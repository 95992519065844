import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, deleteObject } from "firebase/storage";
import { config, firebaseConfigDev, firebaseStr } from "../config";
import { ImageFolder, RootFolder, SubImageFolder } from "../enum/imageCategoryEnums";
import { getStringFromHash, isDev } from "../util/helper";
import { getAuth, signOut } from "firebase/auth";

const firebaseConfig = JSON.parse(getStringFromHash(firebaseStr));
export const fbApp = initializeApp(isDev() ? firebaseConfigDev : firebaseConfig);

const storage = getStorage();
const metadata = {
  contentType: "image/jpeg",
  cacheControl: `private, max-age=${config.imageCacheSeconds}`,
};

/**
 * 
 * @param mainImageFolder 
 * @param mainEntityId vehicleId for vehicles/records, partId for parts 
 * @param id vehicleId for vehicles, recordId for records, partId for parts
 * @param imgs 
 */
export const syncImages = async (
  userId: number,
  mainImageFolder: ImageFolder,
  mainEntityId: number,
  imgs: Record<number, Blob | null>,
  subImagePath?: [SubImageFolder, number]
) => {
  for (const [idx, uploadImage] of Object.entries(imgs)) {
    const imagePath = `${RootFolder.Users}/${userId}/${mainImageFolder}/${mainEntityId}/${subImagePath ? `${subImagePath[0]}/${subImagePath[1]}/` : ``}${idx}.jpg`;

    if (uploadImage) {
      const storageRef = ref(storage, imagePath);
      const file = dataURLtoFile(uploadImage, imagePath);
      // 'file' comes from the Blob or File API
      try {
        await uploadBytes(storageRef, file, metadata);
      } catch (e) {
        console.error('Image upload failed', e);
      }
    } else {
      const desertRef = ref(storage, imagePath);
      try {
        await deleteObject(desertRef);
      } catch (e) {
        console.error('Image deletion failed', e);
      }
    }
  };
}

const getFirebaseDownloadURL = (
  userId: number,
  mainImageFolder: ImageFolder,
  mainEntityId: number,
  idx: number | string,
  subImagePath?: [SubImageFolder, number]) => {
  // const imagePath = `${userId}/${imageFolder}%2F${resourceId}%2F${subResourceId ? `${subImageFolder}%2F${subResourceId}%2F` : ``}${idx}.jpg?alt=media`;
  const imagePath = `${RootFolder.Users}%2F${userId}%2F${mainImageFolder}%2F${mainEntityId}%2F${subImagePath ? `${subImagePath[0]}%2F${subImagePath[1]}%2F` : ``}${idx}.jpg?alt=media`;

  return `https://firebasestorage.googleapis.com/v0/b/${config.firebaseStorageUrl}/o/${imagePath}`;
};

export const generateImagesArrJson = (userId: number, oldImages: object, imgs: Record<number, Blob | null>,
  mainImageFolder: ImageFolder, mainEntityId: number, subImagePath?: [SubImageFolder, number]) => {
  let images: any = {};
  try {
    images = { ...oldImages };
    Object.entries(imgs).forEach(([idx, uploadImage]) => {
      if (uploadImage) {
        images[idx] = getFirebaseDownloadURL(userId, mainImageFolder, mainEntityId, idx, subImagePath);
      } else {
        images[idx] = null;
      }
    });
  } catch (e) {
    console.log(e);
  }
  return images;
}

export const dataURLtoFile = (dataurl: any, filename: string): File => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n-- !== 0) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  const file = new File([u8arr], filename, { type: mime });
  return file;
};

export const firebaseSignout = async () => {
  const auth = getAuth();
  try {
    await signOut(auth);
    console.log("User signed out successfully");
    // You can also add any other logic here after logging out, like redirecting the user
  } catch (error) {
    console.error("Error signing out:", error);
  }
}