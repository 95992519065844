import { LoaderLinear } from "../../loader/loaderLinear.component";
import { Typography } from "@mui/material";
import { ConfigPropsAdditions } from "./configPropsAdditions";
import { useReadConfigPropsQuery, useResetConfigPropMutation } from "../../../state/api/configProps.api";
import { CustomButton } from "../../shared/CustomButton";
import { Restore } from "@mui/icons-material";
import { PopupManager } from "../../../util/popupManager";

export const ConfigPropsAdditionsContainer = () => {

    const { data, isLoading: isLoadingConfigProps, refetch } = useReadConfigPropsQuery({});
    const [resetConfigProp, { isLoading: loadingReset }] = useResetConfigPropMutation();

    if (isLoadingConfigProps) {
        return <LoaderLinear />
    }

    const resetConfigProps = async () => {
        try {
            await resetConfigProp({});
            PopupManager.showSuccessToast('Config props reset completed');
        } catch (e) {
            PopupManager.showErrorToast('COnfig props reset failed')
        }
    }

    return (
        <>
            <Typography variant="subtitle1">Config Props Additions</Typography>
            <CustomButton label="Reset Config Props" onClick={resetConfigProps} EndIcon={Restore}></CustomButton>
            <ConfigPropsAdditions
                loading={isLoadingConfigProps}
                items={data}
            />
        </>
    );
}