import { dispatch, RootState } from "./state/store";
import { PopupContainer } from "./components/popup/popup.container";
import { TopBarContainer } from "./components/topbar/topbar.container";
import { Router } from "./Router";
import { BreadcrumbContainer } from "./components/breadcrumb/breadcrumbs.container";
import { Box, Container, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useLazyGetLoggedInUserQuery, UserApiType, useUpdateUserMutation } from "./state/api/user.api";
import { onAppReady } from "./state/slice/appSlice";
import { config } from "./config";
import { PWA } from "./components/pwa/pwa";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ErrorComponent } from "./components/error/error";
import { ConfirmBox } from "./components/shared/ConfirmBox";
import { getCacheStamp, resetCacheStamp } from "./util/cacheManager";
import { CacheStoreKey } from "./enum/cacheStore.enum";
import { useUser } from "./hooks/useUser";
import { ConfigPropsApiType, useLazyReadConfigPropsQuery } from "./state/api/configProps.api";
import { ConfigProp } from "./types/ConfigProp";
import { vehicleApi, VehicleApiType } from "./state/api/vehicle.api";
import { areaApi, AreaApiType } from "./state/api/area.api";
import { brandApi, BrandApiType } from "./state/api/brand.api";
import { modelApi, ModelApiType } from "./state/api/model.api";
import { partsApi, PartsApiType } from "./state/api/parts.api";
import { recordApi, RecordApiType } from "./state/api/record.api";

const darkTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: 'rgb(138,35,135)'
    }
    // mode: 'dark',
  },
});
export const App: FC = () => {

  const { user, isForced } = useUser();
  const [fetchUser] = useLazyGetLoggedInUserQuery();
  const [fetchConfigProps] = useLazyReadConfigPropsQuery({});
  const [updateUser, { isLoading: loadingUserUpdate, data: updatedUser }] = useUpdateUserMutation();

  const networkError = useSelector((state: RootState) => state.app.networkError);

  const checkCacheState = async () => {
    console.log('Checking App cache...', user);

    // User is fetched from the cache. If user lost the phone he will change the Password. Then accessToken will be changed witht he latest Login.
    // So old user will not be able to do any write acctions
    const cachedUserResult = await fetchUser({ cached: true });
    if (cachedUserResult.error) {
      console.error(cachedUserResult.error);
    }
    dispatch(onAppReady(true));

    // App start completed -------------

    const configPropsResult = await fetchConfigProps({});
    const freshUserResult = await fetchUser({ cached: false });

    const serverConfigPropsArr: ConfigProp[] = configPropsResult?.data ?? [];
    const serverConfigPropMap: Record<string, string> = {};
    serverConfigPropsArr.forEach(c => {
      serverConfigPropMap[c.key] = c.value;
    });

    reset(areaApi, serverConfigPropMap, CacheStoreKey.areaDBStamp);
    reset(modelApi, serverConfigPropMap, CacheStoreKey.modelDBStamp);
    reset(brandApi, serverConfigPropMap, CacheStoreKey.brandDBStamp);

    const serverUser = freshUserResult.data;
    if (serverUser && (serverUser?.cacheStamp !== getCacheStamp(CacheStoreKey.userDBStamp))) {
      console.log('New device login');
      // We have to reset it here since there may be newly added vehicles or records by myself. They should be fetched soon as possible.
      reset(partsApi, serverConfigPropMap, CacheStoreKey.partsDBStamp);
      reset(vehicleApi, serverConfigPropMap, CacheStoreKey.vehiclesDBStamp);
      reset(recordApi, serverConfigPropMap, CacheStoreKey.recordDBStamp);

      // updateUser have the functionality to resetCache and send it to BE
      updateUser({
        user: {
          id: serverUser.id
        }
      });
    } else {
      console.log('Same device login');
    }
  }

  const reset = (
    api: AreaApiType | BrandApiType | ConfigPropsApiType | ModelApiType | PartsApiType | RecordApiType | UserApiType | VehicleApiType,
    serverConfigPropMap: Record<string, string>,
    key: CacheStoreKey
  ) => {
    if (serverConfigPropMap[key] !== getCacheStamp(key)) {
      console.log('Resetting------', key);
      resetCacheStamp(key, serverConfigPropMap[key]);
      dispatch(api.util.invalidateTags([key as never]));
    }
  }

  useEffect(() => {
    checkCacheState();
  }, []);

  if (networkError) {
    return <ErrorComponent
      title="Unable to communicate with the server"
      text="Please try again in few minutes or contact odocert@gmail.com" />
  }
  return (
    <>
      <PWA />
      <ThemeProvider theme={darkTheme}>
        <div className="App">
          <PopupContainer />
          <>
            <TopBarContainer />
            <ConfirmBox />
            {(user?.id === config.adminUserId) && <Link to="/admin"><Typography variant="caption" color="red">Admin User</Typography></Link>}
            {isForced && <Typography variant="caption" marginLeft={4} color="red">Forced logged user</Typography>}
            <BreadcrumbContainer />
            <Container maxWidth="xl">
              <Router />
              <Box sx={{ height: '100px', width: '100px', display: { md: 'none' } }} />
            </Container>
          </>
        </div>
      </ThemeProvider>
    </>
  );
}