import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../../hooks/useUser";
import { useReadPartQuery, useUpdatePartMutation } from "../../../state/api/parts.api";
import { Part } from "../../../types/Part";
import { PartCreate } from "../create/partCreate";
import { parseEntityId } from "../../../util/helper";
import { Unauthorized } from "../../unauthorized/unauthorized.component";
import { LoaderLinear } from "../../loader/loaderLinear.component";
import { PageHeader } from "../../shared/PageHeader";

export const PartUpdateContainer = () => {
    const { pid } = useParams();
    const { user } = useUser();
    const [updatePart, { isLoading: loadingUpdate }] = useUpdatePartMutation();
    const { data: part, isLoading: loadingPart } = useReadPartQuery({ id: parseEntityId(pid) });

    const navigate = useNavigate();

    const handleOnSave = async (part: Part, imgs: Record<number, Blob | null>) => {
        const result: any = await updatePart({ part, imgs });
        if (!result.error) {
            navigate(`/sparepart/${part.id}`);
        }
    }

    if (!user) {
        return <Unauthorized />
    }

    if (!part) {
        return <LoaderLinear />
    }

    return <>
        <PageHeader
            header={'Update Parts'}
            subHeader={''}
            showEditOptions={false}
        />
        <PartCreate initialState={part} loading={loadingUpdate || loadingPart} user={user} onSave={handleOnSave} />
    </>
}

