import { config } from "../../../config";
import { Part } from "../../../types/Part";
import { SelectOption } from "../../../types/SelectOption";
import { LabelHelper } from "../../../util/labelHelper";

export const sanitizePart = (p: Part, models: SelectOption[], brands: SelectOption[]) => {
  if (p.yearMin === undefined || `${p.yearMin}` === '') {
    p.yearMin = config.yMin;
  }

  if (p.yearMax === undefined || `${p.yearMax}` === '') {
    p.yearMax = config.yMax;
  }

  setKeywordsOfParts(p, models, brands);
  return p;
};

// This method is used by other places where edit Parts
export const setKeywordsOfParts = (p: Part, models: SelectOption[], brands: SelectOption[]) => {
  const fullStr = p.desc + ' ' +
    LabelHelper.getBrandLabelEntity(p, brands) + ' ' +
    (p.yearMin ?? '').toString() + ' ' +
    (p.yearMax ?? '').toString() + ' ' +

    p.vehicleTypes.map(vehicleType => LabelHelper.getVehicleTypeLabel(vehicleType)).reduce((a, b) => a + ' ' + b, '') +

    p.categories.reduce((a, b) => a + ' ' + b, '') + ' ' +
    LabelHelper.getModelLabelEntity(p, models) + ' ' +
    p.chassis + ' ' +
    p.desc + ' ';

  // all the keywords are splitted together here
  p.keywords = fullStr.toLocaleLowerCase()
    .replaceAll('N/A', '')
    .replaceAll('null', '')
    .replaceAll('undefined', '')
    .split(' ').filter(word => word.length > 1);
  // console.log('keywords', p.keywords);
  return p;
}
