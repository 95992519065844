import { FuelType } from "../enum/fuelType.enum";
import { ListingType } from "../enum/vehicleListing.enum";
import { transmissionTypes } from "../selectOptions/transmissionTypes";
import { DEFAULT_SELECTED_VEHICLE_TYPE } from "../selectOptions/vehicleTypes";
import { User } from "../types/User";
import { Vehicle } from "../types/Vehicle";

export const defaultVehicle = (user: User): Vehicle => ({
    // ...defaultLocation(user),
    id: 0,
    chassis: "",
    date: new Date(),
    gear: transmissionTypes[0].id,
   
    regNo: "",
    cc: 0,
    odo: 0,
    fuel: FuelType.Petrol,
    fuelPrice: 0,

    brand: -1, // DEFAULT_SELECTED_MANUFACTURER,
    model: -1,

    // Area info
    lat: -1,
    lng: -1,
    country: user.country,
    province: -1,
    district: -1,
    area: -1,
    //

    type: DEFAULT_SELECTED_VEHICLE_TYPE,
    year: new Date().getFullYear(),

    deleted: false,
    users: [],
    UserId: user.id,
    
    extraProps: {},
    images: {},

    listing: ListingType.ListingForMyOwn,

    keywords: [],

    price: 0,
    phone: user.shopPhone ?? user.phone ?? '',

    desc: '',
    updatedAt: new Date(),
    createdAt: new Date(),
})