import Dialog from '@mui/material/Dialog';
import { FC } from 'react';
import { ServiceRecord } from '../../types/ServiceRecord';
import { LineChart } from './lineChart';
import { ServiceType } from '../../selectOptions/serviceTypes';
// import { SelectInput } from '../shared/SelectInput';
// import { SelectOption } from '../../types/SelectOption';
import { Grid } from '@mui/material';

interface Props {
    // isLoading: boolean;
    open: boolean;
    onClose: () => void;
    data: ServiceRecord[];
    onRecordTypesChange: (types: ServiceType[]) => void;
}

export const ChartDialog: FC<Props> = ({ open, onClose, data, onRecordTypesChange }) => {
    // const [selectedServiceType, setSelectedServiceType] = useState(-1);
    // const serviceTypeOptions: SelectOption[] = [
    //     {
    //         id: 1,
    //         label: 'All Records',
    //         meta: []
    //     },
    //     {
    //         id: 2,
    //         label: 'All Records except fuel records',
    //         meta: serviceTypesExceptFuelRecords
    //     },
    //     {
    //         id: 3,
    //         label: 'Fuel Records',
    //         meta: [ServiceType.FuelTopUp]
    //     }
    // ]
    // if (isLoading) {
    //     return <div>Loading...</div>
    // }

    // const handleRecordTypeChange = (key: string, value: string | number) => {
    //     const selectedTypeNum = Number(value);
    //     const selectedType =  serviceTypeOptions.find(e => e.id === selectedTypeNum);

    //     if (selectedType) {
    //         setSelectedServiceType(selectedTypeNum)
    //         onRecordTypesChange(selectedType.meta);
    //     }
    // }

    return (
        <Dialog fullWidth maxWidth='lg' onClose={onClose} open={open} sx={{ overflow: 'hidden' }}>
            <Grid container>
                {/* <Grid item width='100%'>
                    <Grid container justifyContent="end">
                        <Grid item xs={6} sm={4} marginRight={4} marginTop={1}>
                            <SelectInput
                                name="display"
                                label="Display"
                                // variant='outlined'
                                size='small'
                                options={serviceTypeOptions}
                                disabled={false}
                                onChange={handleRecordTypeChange}
                                value={selectedServiceType}
                            />
                        </Grid>
                    </Grid>
                </Grid> */}
                <Grid item width='100%'>
                    <LineChart data={data} />
                </Grid>
            </Grid>
        </Dialog>
    );
}
