import { useEffect } from "react";
import { LoginUIMode } from "../../enum/login.ui.mode";
import { changeLoginMode, onLoginVisibilityChange } from "../../state/slice/appSlice";
import { useAppDispatch, useAppSelector } from "../../state/store";
import { Login } from "./login";
import { Box, Grid, Typography } from "@mui/material";
import { Welcome } from "./welcome";
import packageJson from '../../package.alias.json';

export const LoginContainer = () => {
  const dispatch = useAppDispatch();
  const loginUIMode = useAppSelector((state) => state.app.login.mode);

  const handleModeChange = (mode: LoginUIMode) => {
    dispatch(changeLoginMode(mode));
  };

  useEffect(() => {
    dispatch(onLoginVisibilityChange(true));
    return () => {
      dispatch(onLoginVisibilityChange(false));
    }
  }, [])

  return <Box
    sx={{
      margin: 'auto',
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      // maxWidth: '300px',
    }}
  >
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      textAlign={"center"}
    >
      <Welcome />

      <Grid item xs={12} sm={6}  >
        {/* <SocialLoginContainer /> */}
        <Login onModeChange={handleModeChange} mode={loginUIMode} />
      </Grid>
    </Grid>

    <Typography marginTop={1} variant="caption" textAlign="center">Version {packageJson.version}</Typography>
  </Box>
};
