import { DeleteForever } from "@mui/icons-material";
import { Grid, IconButton, TextField, Typography } from "@mui/material";
import { FC, useState } from "react";
import Resizer from "react-image-file-resizer";
import { config } from "../../config";
import { PopupManager } from "../../util/popupManager";

interface Props {
  index: number;
  label?: string;
  disabled: boolean;
  defaultImageUrl: string | null;
  onImageSelected: (base64: Blob | null, index: number) => void;
  onEnter?: () => void;
}
export const ImageInput: FC<Props> = ({ index, label, defaultImageUrl, onImageSelected, disabled, onEnter }) => {
  const [img, setImg] = useState<string | null>(defaultImageUrl);
  const [displayImage, setDisplayImage] = useState(true);

  const fileChangedHandler = (event: any) => {
    let fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          config.maxDimentionsForImageUpload,
          config.maxDimentionsForImageUpload,
          "JPEG",
          100,
          0,
          (uri) => {
            onImageSelected(uri as Blob, index);
            setDisplayImage(true);
            setImg(uri as string);
          },
          "base64",
          config.minDimentionsForImageUpload,
          config.minDimentionsForImageUpload,
        );
        if (index === 0) {
          Resizer.imageFileResizer(
            event.target.files[0],
            config.maxDimentionsForImageThumbnailUpload,
            config.maxDimentionsForImageThumbnailUpload,
            "JPEG",
            100,
            0,
            (uri) => {
              onImageSelected(uri as Blob, -1);
              setDisplayImage(true);
              setImg(uri as string);
            },
            "base64",
            config.minDimentionsForImageThumbnailUpload,
            config.minDimentionsForImageThumbnailUpload,
          );
        }
      } catch (err) {
        console.log(err);
        PopupManager.showErrorToast('Please select a valid image');
      }
    }
  };

  const handleKeyDown = (e: any) => {
    if (onEnter) {
      if (e.key === 'Enter') {
        onEnter();
      }
    }
  }

  const handleDelete = () => {
    onImageSelected(null, index);
    setDisplayImage(false);
  }

  return (
    <div>
      <u><Typography variant="subtitle1">{label}</Typography></u>
      <TextField
        disabled={disabled}
        onChange={fileChangedHandler}
        required
        fullWidth

        // inputProps={{accept: 'image/x-png, image/jpeg'}}

        autoComplete="off"
        type="file"
        // sx={{ marginTop: 5, height: config.inputHeight, minWidth: config.inputMinWidth }}
        variant="standard"
        onKeyDown={handleKeyDown}
      />

      <Grid paddingTop={1}>
        {img && displayImage &&
          <>
            <img alt="preview" src={img} style={{ width: '150px', opacity: disabled ? '0.5' : '1' }} onError={() => setDisplayImage(false)} />
            {img !== config.defaultVehicleImageUrl && <IconButton disabled={disabled} color="primary" aria-label="upload picture" component="label" onClick={handleDelete}>
              <DeleteForever />
            </IconButton>}
          </>}
      </Grid>

    </div>
  );
};
