import { useParams } from "react-router-dom";
import { OwnerTypes } from "../enum/ownerTypes.enum";
import { useUser } from "./useUser";

export const useUserForEntityFetch = (ownerType: OwnerTypes) => {
  const { sellerId } = useParams();
  const { user } = useUser();

  switch (ownerType) {
    case OwnerTypes.Any:
      return -1;

    case OwnerTypes.ProvidedAsParam:
      return Number(sellerId);

    case OwnerTypes.My:
      return user?.id ?? -1
  }
};
