import { useState } from "react";

export const useFormState = <T,>(obj: T) => {
  const [state, setState] = useState(obj);

  const changeProperty = (prop: string, value: string | string[] | number | boolean | number[] | undefined): T => {
    const next = { ...state, [prop]: value };
    setState(next);
    return next;
  };

  const changeBulk = (obj: any): T => {
    const next = { ...state, ...obj };
    setState(next);
    return next;
  };

  const changeExtraProperty = (prop: string, value: any) => {
    const extraPropsObject = ({...(state as any).extraProps});
    if (value === null || value === undefined) {
      // eslint-disable-next-line
      delete extraPropsObject[prop];
    } else {
      extraPropsObject[prop] = value;
    }
    setState((prev: any) => ({
      ...prev,
      extraProps: extraPropsObject,
    }));
  };


  const changeBulkExtraProperty = (obj: any) => {
    const nextExtraProps = { ...(state as any).extraProps, ...obj };
    setState((prev: any) => ({
      ...prev,
      extraProps: nextExtraProps,
    }));
  };

  const changeWithExtraProperty = (key: string, value: string | number, fromSuggestions?: boolean) => {
    // Do not change the order below
    if (fromSuggestions) {
      changeProperty(key, value);
      changeExtraProperty(key, undefined);
    } else {
      changeProperty(key, -1);
      changeExtraProperty(key, value);
    }
  }

  return {
    state, changeProperty, changeExtraProperty, changeBulk, changeBulkExtraProperty, changeWithExtraProperty
  }
};
