import { RecordVisibility } from "../enum/recordVisibility.enum";
import { ServiceRecord } from "../types/ServiceRecord";
import { User } from "../types/User";

export const defaultServiceRecord = (user: User): ServiceRecord => ({
    id: 0,
    date: new Date(),
    categories: [],
    odo: 0,
    desc: "",
    VehicleId: -1,
    UserId: user.id,
    images: {},
    extraProps: {},
    visibility: RecordVisibility.public,
    createdAt: new Date(),
    updatedAt: new Date()
})