/* eslint-disable @typescript-eslint/no-extraneous-class */

import { AuthProvider } from "../enum/authProvider.enum";
import { fuelTypeOptions } from "../selectOptions/fuelTypes";
import { partsTypes } from "../selectOptions/partsTypes";
import { serviceTypes } from "../selectOptions/serviceTypes";
import { transmissionTypes } from "../selectOptions/transmissionTypes";
import { vehicleTypes } from "../selectOptions/vehicleTypes";
import { Area } from "../types/Area";
import { INode } from "../types/INode";
import { Part } from "../types/Part";
import { SelectOption } from "../types/SelectOption";
import { Vehicle } from "../types/Vehicle";
import { countryList } from "./countries";

export class LabelHelper {
    public static displayPrice(amount: number) {
        if (isNaN(amount) || amount === 0) {
            return `Negotiable/=`
        } else {
            return `${amount}/=`
        }
    }

    public static authProvider(prov: AuthProvider) {
        return AuthProvider[prov];
    }

    public static displayFilterLabel(key: string, value: any, brands: SelectOption[], models: SelectOption[], areas: SelectOption[], countryCode: string, provinceCode: number | undefined, districtCode: number | undefined): string {
        switch (key) {
            case 'yearMin':
                return `Min Year: ${value}`;

            case 'yearMax':
                return `Max Year: ${value}`;

            case 'year':
                return `Year: ${value}`;

            case 'priceMin':
                return `Min Price: ${value}`;
            case 'priceMax':
                return `Max Price: ${value}`;

            case 'brand':
                return `${LabelHelper.getBrandLabel(value, brands)}`;

            case 'model':
                return `${LabelHelper.getModelLabel(value, models)}`;

            case 'odoMin':
                return `Min Millage: ${value}`;
            case 'odoMax':
                return `Max Millage: ${value}`;

            case 'type':
                return `${LabelHelper.getVehicleTypeLabel(value)}`;

            case 'fuel':
                return `${LabelHelper.getFuelTypeLabel(value)}`;

            case 'gear':
                return `${LabelHelper.getTransmissionTypeLabel(value)}`;
            case 'province':
                return `${LabelHelper.getProvinceLabel(countryCode, provinceCode)}`;

            case 'district':
                return `${LabelHelper.getDistrictLabel(countryCode, provinceCode, districtCode)}`;

            case 'area':
                return `${LabelHelper.getAreaLabel(value, areas)}`;


            case 'vehicleType':
                return `${LabelHelper.getVehicleTypeLabel(value)}`;

            case 'category':
                return `${LabelHelper.convertPartCategoryLabels(partsTypes, [value], []).toString()}`;

        }
        // console.log('filter err', key);
        return key;
    }

    public static displayRegNo(reg: string): string {
        if(!reg) {
            return '----';
        }
        if (isNaN(Number(reg))) {
            let isNaNPrevChar = false;
            let newString = "";
            reg?.split("").forEach((char, index) => {
                const isNan = isNaN(Number(char));
                if (index === 0) {
                    newString += char;
                } else if (isNan) {
                    // string
                    if (isNaNPrevChar) {
                        // string
                        newString += char;
                    } else {
                        newString += `-${char}`;
                    }
                } else {
                    // number
                    if (isNaNPrevChar) {
                        // string
                        newString += `-${char}`;
                    } else {
                        newString += char;
                    }
                }
                isNaNPrevChar = isNan;
            });
            return newString.toUpperCase();
        } else {
            const len = reg.length;
            let newStr = '';
            for (let k = len - 1; k >= 0; k--) {
                if ((len - 1 - k) % 4 === 0 && (len - 1 - k) !== 0) {
                    newStr = `${reg[k]}-${newStr}`;
                } else {
                    newStr = `${reg[k]}${newStr}`;
                }
            }
            return newStr.toUpperCase();
        }
    };

    public static getFuelTypeLabel(id: number): string {
        const type = fuelTypeOptions.find((s) => s.id === id);
        return type != null ? type.label : "N/A";
    };

    public static convertPartCategoryLabels(partTypes: INode[], categories: string[], results: string[]) {
        for (const part of partTypes) {
            if (categories.includes(part.value)) {
                results.push(part.label);
            }
            if (part.children && part.children.length > 0) {
                LabelHelper.convertPartCategoryLabels(part.children, categories, results);
            }
        }
        return results;
    }

    public static getBrandLabel(value: number, brands: SelectOption[]): string {
        const option = brands.find((s) => s.id === value);
        return option != null ? option.label : "N/A";
    };

    public static getAreaLabelEntity(entity: Vehicle | Part, areas: SelectOption[]): string {
        if (entity.area === -1) {
            const extraProps = (entity.extraProps);
            return extraProps.area ?? '';
        }

        const option = areas.find((s) => s.id === entity.area);
        return option != null ? option.label : "N/A";
    };

    public static getBrandLabelEntity(entity: Vehicle | Part, brands: SelectOption[]): string {
        if (entity.brand === -1) {
            const extraProps = (entity.extraProps);
            return extraProps.brand ?? '';
        }

        const option = brands.find((s) => s.id === entity.brand);
        return option != null ? option.label : "N/A";
    };

    // public static getAreaLabelEntity(value: number, areas: SelectOption[]): string {
    //     const option = areas.find((s) => s.id === value);
    //     return option != null ? option.label : "N/A";
    // };

    public static getCountryLabel(country: string): string {
        const option = countryList.find((c) => c.id === country);
        return option != null ? option.label : "N/A";
    };

    public static getProvinceLabel(country: string, province: number | undefined): string {
        // public static getProvinceLabel(entity: Vehicle | Part): string {
        // console.log(entity)
        // console.log(countryList.find((c) => c.id === entity.country)?.provinceList);

        const option = countryList.find((c) => c.id === country)?.provinceList?.find(p => p.id === province)
        return option != null ? option.label : "N/A";
    };

    public static getDistrictLabel(country: string, province: number | undefined, district: number | undefined): string {
        // public static getDistrictLabel(entity: Vehicle | Part): string {
        const option = countryList.find((c) => c.id === country)?.provinceList?.find(p => p.id === province)?.districts?.find(d => d.id === district)
        return option != null ? option.label : "N/A";
    };

    public static getAreaLabel(areaId: number, areas: SelectOption[], extraProps?: any) { // TODO: avoid any
        if(areaId === -1 && extraProps) {
            const extra = (extraProps);
            return extra.area;
        }
        const option = areas.find((a) => a.id === areaId);
        return option?.label;
    };

    public static getModelLabel(value: number, models: SelectOption[]): string {
        const option = models.find((s) => s.id === value);
        return option != null ? option.label : "N/A";
    };

    public static getModelLabelEntity(entity: Vehicle | Part, models: SelectOption[]): string {

        if (entity.model === -1) {
            const extraProps = (entity.extraProps);
            return extraProps.model;
        }

        const option = models.find((s) => s.id === entity.model);
        return option != null ? option.label : "N/A";
    };

    public static getTransmissionTypeLabel(id: number): string {
        const type = transmissionTypes.find((s) => s.id === id);
        return type != null ? type.label : "N/A";
    };

    public static getVehicleTypeLabel(id: number): string {
        const type = vehicleTypes.find((s) => s.id === id);
        return type != null ? type.label : "N/A";
    };

    public static getServiceTypesLabel(categories: number[], short?: boolean): string {
        if (categories.length === 1) {
            return serviceTypes.find(c => c.id === categories[0])?.label ?? 'Service record';
        } else if (short) {
            return serviceTypes.filter(s => categories?.includes(s.id)).map(i => `${i.shortLabel}`).toString();
        } else {
            return serviceTypes.filter(s => categories?.includes(s.id)).map(i => `${i.label}`).toString();
        }
    }
}

