import { Chip, Grid } from "@mui/material";
import { FC } from "react";
import { NumberInput } from "../../shared";
import EditIcon from '@mui/icons-material/Edit';
import { Validators } from "../../../util/validators";
import { InputGrid } from "../../shared/InputGrid";
import { Vehicle } from "../../../types/Vehicle";
import { FuelType } from "../../../enum/fuelType.enum";

export interface AProps {
    extraProps: any;
    onExtraChange: (obj: any) => void;
    loading: boolean;
    ready: boolean;
    vehicle: Vehicle;
    onFuelPriceChange: () => void;
}
export const FuelTopup: FC<AProps> = ({ extraProps, onExtraChange, onFuelPriceChange, vehicle, loading, ready }) => {

    const extraPropsObject = extraProps;
    const { fuelPrice } = vehicle;

    const beforeChange = async (prop: string, value: string | number) => {

        if (!isNaN(fuelPrice) && fuelPrice > 0) {

            const extra = {
                [prop]: value
            };
            if (prop === 'cost') {
                const cost = Number(value);
                if (!isNaN(cost)) {
                    const vol = cost / fuelPrice;
                    extra.volume = (vol).toFixed(2);
                }
            } else if (prop === 'volume') {
                const volume = Number(value);
                if (!isNaN(volume)) {
                    extra.cost = volume * fuelPrice;
                }
            }
            onExtraChange(extra);
        }
    }
    const fuelPriceLabel = vehicle.fuel === FuelType.Electric ? "KWH price" : "fuel price";

    return <>
        <InputGrid minWidth={12}>
            <div onClick={onFuelPriceChange} style={{ cursor: 'pointer' }}>
                <Chip
                    sx={{ margin: 2 }}
                    onDelete={onFuelPriceChange}
                    deleteIcon={<EditIcon />}
                    label={isFulePriceAvailable(fuelPrice) ? `${fuelPriceLabel}: $ ${fuelPrice}` : `Set a valid ${fuelPriceLabel}`}
                    color={isFulePriceAvailable(fuelPrice) ? "success" : "error"}
                />
            </div>
        </InputGrid>
        <InputGrid minWidth={4}>
            <NumberInput
                value={extraPropsObject.cost ?? 0}
                name="cost"
                label="Total Cost"
                disabled={loading || !ready}
                error={!Validators.numberPositive(extraPropsObject.cost)}
                onChange={beforeChange}
            />
        </InputGrid>
        <InputGrid minWidth={4}>
            <NumberInput
                value={extraPropsObject.volume ?? 0}
                error={!Validators.volume(extraPropsObject.volume)}
                name="volume"
                label={vehicle.fuel === FuelType.Electric ? "KWH" : "Volume"}
                disabled={loading || !ready}
                onChange={beforeChange}
            />
        </InputGrid>
    </>
}

export const isFulePriceAvailable = (price: any) => {
    const val = Number(price);
    if (isNaN(val) || val === 0) {
        return false;
    }
    return true;
}

