import {
  Box,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { withScroller } from "../../../hoc/withScroller";
import { FC } from "react";
import { ServiceRecord } from "../../../types/ServiceRecord";
import { getFormattedDate, getServiceTypes } from "../../../util/helper";
import { GridItemAdd } from "../../shared/addNew/gridItemAdd";
import { TimelineDot } from "@mui/lab";
import { DynamicFeed, PostAdd } from '@mui/icons-material';
import { LabelHelper } from "../../../util/labelHelper";
import { Vehicle } from "../../../types/Vehicle";
import { ImageCardView } from "../../shared/ImageCardView";

export interface RecordsList {
  onSelect: (Record: ServiceRecord) => void;
  onAdd: () => void;

  vehicle: Vehicle;
  isOwner: boolean;
  hasMore: boolean;
  items: ServiceRecord[];
  loading: boolean;
  onLoadMore: (nextLimit: number) => void;
}
const RecordsGridViewComp: FC<RecordsList> = ({
  isOwner,
  items,
  vehicle,
  onSelect,
  onAdd,
}) => {
  if (items) {
    return (
      <Grid container spacing={1}>
     {isOwner && <GridItemAdd Icon={PostAdd} text="Add New Record" subText="for your vehicle" onAdd={onAdd} />}
        {items.map((r) => (
          <Grid onClick={() => onSelect(r)} key={`${r.id}${r.createdAt.toString()}`} xs={12} sm={6} md={4}  item>
            <Box sx={{ margin: 0, cursor: 'pointer' }}>
              <CardHeader
                title={LabelHelper.getServiceTypesLabel(r.categories)}
                subheader={getFormattedDate(r.date)}
                avatar={
                  r.categories?.length === 1 ? <TimelineDot color={getServiceTypes(r.categories)[0].color}>
                    {getServiceTypes(r.categories)[0].Icon}
                  </TimelineDot> : <TimelineDot color={'warning'}>
                     <DynamicFeed />
                  </TimelineDot>
                }
              />
              <ImageCardView entity={r} />
             
              <CardContent>
                <Typography variant="body2">Millage: <b> {r.odo} km | miles </b></Typography>
                <Typography variant="body1">Description: <b> {r.desc}</b></Typography>
              </CardContent>
              <Divider variant="middle" />
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  }
  return <div>Record Not found</div>;
};

export const RecordsGridView =  withScroller(RecordsGridViewComp);
