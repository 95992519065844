import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { config } from "../../../config";
import { usePaginatedData } from "../../../hooks/usePaginatedData";
import { useDeleteVehicleMutation, useSearchVehiclesQuery } from "../../../state/api/vehicle.api";
import { Vehicle } from "../../../types/Vehicle";
import { LoaderLinear } from "../../loader/loaderLinear.component";
import { VehiclesAdmin } from "./vehicles.admin"
import { OwnerTypes } from "../../../enum/ownerTypes.enum";
import { PopupManager } from "../../../util/popupManager";

export const VehiclesAdminContainer = () => {
    const navigate = useNavigate();
    const [deleteVehicle, { isLoading: loadingDelete }] = useDeleteVehicleMutation();

    // Pagination starts
    const [query, setQuery] = useState({
        limit: config.pageSize,
        offset: 0,
        keywords: '',
        filter: {},
        ownerType: OwnerTypes.Any
    });
    const { data, isFetching, refetch } = useSearchVehiclesQuery(query);
    const { mergedData, handleLoadMore, resetData } = usePaginatedData(data, setQuery);
    // pagination ends

    const handleOnDelete = async (v: Vehicle) => {
        if (v.id) {
            const newV = { ...v };
            newV.deleted = true;
            const result: any = await deleteVehicle(v.id);
            if (result.error) {
                PopupManager.showErrorToast('Vehicle deletion failed');
            }
            else {
                PopupManager.showSuccessToast('Vehicle deleted');
                resetData();
                refetch();
            }
        };
    }

    const handleSelect = (v: Vehicle) => {
        navigate(`/vehicle/${v.id}`);
    }

    return (
        <>
            {loadingDelete && <LoaderLinear />}
            {/* <VehicleFilter filters={vehicleFilters} onFilterChange={handleFilterChange} /> */}
            <VehiclesAdmin
                hasMore={data?.length > 0}
                loading={isFetching}
                items={mergedData}
                onLoadMore={handleLoadMore}
                onDelete={handleOnDelete}
                onSelect={handleSelect}
            />
        </>
    );
}