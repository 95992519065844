import * as React from "react";
import Avatar from "@mui/material/Avatar";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { User } from "../../types/User";
import { getFirstChar } from "../../util/helper";
import { deepOrange } from "@mui/material/colors";
import { Logout, StarRate, ManageAccounts } from '@mui/icons-material';
import { AuthProvider } from "../../enum/authProvider.enum";
import { UserImageType } from "../../enum/user.type";

interface Props {
  user: User | null | undefined;
  isLoginVisible: boolean;
  onUpdateProfile: (user: User) => void;
  onLogout: () => void;
  onLogin: () => void;
  onNavigate: (path: string) => void;
}
export const AvatarBadge: React.FC<Props> = ({ user, isLoginVisible, onNavigate, onLogout, onUpdateProfile, onLogin }) => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const profilePic =  user?.images[UserImageType.Profile];

  return (
    <Box sx={{ flexGrow: 0, marginLeft: 1 }}>
      {user ?
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Typography marginRight={1} variant="overline" color="white">{user.name}</Typography>
          <Avatar className="avatar" sx={{ bgcolor: deepOrange[500] }} src={profilePic}>{getFirstChar(user.name)}</Avatar>
        </IconButton> :
        <>{isLoginVisible ? <></> : <Button onClick={onLogin} sx={{ color: 'white' }}>Login</Button>}</>
      }

      {user && <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {user && <MenuItem onClick={() => {
          handleCloseUserMenu();
          onNavigate(`favourites`);
        }}>
          <StarRate />
          <Typography marginLeft={1} variant="subtitle2" textAlign="center">Favourites</Typography>
        </MenuItem>}

        <MenuItem onClick={() => {
          handleCloseUserMenu();
          onUpdateProfile(user);
        }}>
          <ManageAccounts />
          <Typography marginLeft={1} variant="subtitle2" textAlign="center">Account Settings</Typography>
        </MenuItem>

        <MenuItem onClick={() => {
          handleCloseUserMenu();
          onLogout();
        }}>
          <Logout />
          <Typography marginLeft={1} variant="subtitle2" textAlign="center">Logout</Typography>
        </MenuItem>
      </Menu>}
    </Box>
  );
};
