import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { vehicleApi } from "./api/vehicle.api";
import { appSlice, onNetworkError } from "./slice/appSlice";
import axios from "axios";
import { config } from "../config";
import { recordApi } from "./api/record.api";
import { userApi } from "./api/user.api";
import { clearAllCookies } from "../util/cacheManager";
import { partsApi } from "./api/parts.api";
import { modelApi } from "./api/model.api";
import { brandApi } from "./api/brand.api";
import { areaApi } from "./api/area.api";
import { configPropApi } from "./api/configProps.api";

const failedRequestsRecoveryURL = `user/refreshToken`;
axios.defaults.withCredentials = true;
axios.defaults.baseURL = config.serverUrl;
let isRefreshingToken = false;

const delay = async (ms: number) => {
  return await new Promise((resolve) => setTimeout(resolve, ms));
};

axios.interceptors.request.use(
  (request: any) => {
    if (request.method === "post") {
      request.headers["Content-Type"] = "application/json";
    }
    return request;
  },
  async (error) => {
    return await Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if(error.code === 'ERR_NETWORK') {
      console.error('ERR_NETWORK');
      dispatch(onNetworkError(true));
    }
    const originalRequest = error.config;
    if (error.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      console.log('403 for', originalRequest.url);
      try {
        if (isRefreshingToken) {
          // eslint-disable-next-line no-unmodified-loop-condition
          while(isRefreshingToken) {
            console.log("while await " , originalRequest.url);
            await delay(500);
            if (!isRefreshingToken) {
              console.log('while await return ', originalRequest.url);
              break;
            }
          }
        } else {
          isRefreshingToken = true;
          console.log("failedRequestsRecoveryURL sending: ", originalRequest.url);
          await axios.get(failedRequestsRecoveryURL);
          isRefreshingToken = false;
        }

        const next = {
          ...originalRequest,
          headers:
            originalRequest.method === "post" ? originalRequest.headers : {}, // tor fixing an axios header issue
        };
        console.log('axios repeat send previous url: ', originalRequest.url);
        return await axios(next);
      } catch (e) {
        isRefreshingToken = false;
        // alert('Internet connectivity is not stable.')
        console.log('Failed repeat sending: ', originalRequest.url);
        console.log(e);
        clearAllCookies();
        // TODO: show user a message to reload the page
        // window.location.reload();
      }
    }
    return await Promise.reject(error);
  }
);

const reducer = {
  [configPropApi.reducerPath]: configPropApi.reducer,
  [vehicleApi.reducerPath]: vehicleApi.reducer,
  [recordApi.reducerPath]: recordApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [partsApi.reducerPath]: partsApi.reducer,
  [modelApi.reducerPath]: modelApi.reducer,
  [brandApi.reducerPath]: brandApi.reducer,
  [areaApi.reducerPath]: areaApi.reducer,
  app: appSlice.reducer
};
export const store = configureStore({
  reducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(configPropApi.middleware)
      .concat(recordApi.middleware)
      .concat(userApi.middleware)
      .concat(vehicleApi.middleware)
      .concat(partsApi.middleware)
      .concat(modelApi.middleware)
      .concat(brandApi.middleware)
      .concat(areaApi.middleware),
});

// // Infer the `RootState` and `AppDispatch` types from the store itself
// // export type RootState = typeof reducer;
// export type RootState = ReturnType<typeof store.getState>
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;



// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const dispatch = store.dispatch;
export const subscribe = store.subscribe;
export const getState = store.getState;
