import { FC, useState } from "react";
import { useUser } from "../../hooks/useUser";
import { ChartDialog } from "./chartDialog";
import { ServiceType } from "../../selectOptions/serviceTypes";
import { Vehicle } from "../../types/Vehicle";
import { ServiceRecord } from "../../types/ServiceRecord";

interface Props {
    vehicle: Vehicle;
    items: ServiceRecord[];
    open: boolean;
    onClose: () => void
}

export const ChartContainer: FC<Props> = ({ open, vehicle, items, onClose }) => {
    const [ recordTypes, setRecordTypes] = useState<ServiceType[]>([]);

    const { user } = useUser();
    const isOwner = vehicle?.UserId === user?.id;

    // const { data, isLoading } = useSearchRecordsQuery({ limit: config.chartFuelRecordsLimit, offset: 0, filter: {VehicleId: vehicle.id, categories: recordTypes}, isOwner });

    return <>
        <ChartDialog open={open} onRecordTypesChange={setRecordTypes} onClose={() => onClose()} data={items} />
    </>
}