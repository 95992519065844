import { Storefront, Edit } from "@mui/icons-material";
import { Button, Grid, Link, Typography } from "@mui/material";
import { FC } from "react";

export const PageHeader: FC<{
    header: string,
    subHeader: string,
    small?: boolean,
    showEditOptions: boolean;
    rightButton?: {
        label: string;
        link: string;
    }
}> = ({ header, subHeader, small, rightButton, showEditOptions }) => {
    return <>
        <Grid container justifyContent="space-between">
            {(header || !showEditOptions) ? <Typography variant="h2" fontSize={small ? 20 : 40}>{header}</Typography> :
                <Link href="/user/edit" underline="none">
                    <Button variant="text" startIcon={<Edit />}> Add shop name </Button>
                </Link>

            }
            {rightButton && <Link href={rightButton.link}><Button variant="text" startIcon={<Storefront />}>
                {rightButton.label}
            </Button>
            </Link>}
        </Grid>
        {(subHeader || !showEditOptions) ? <Typography variant="overline">{subHeader}</Typography> :
            <Link href="/user/edit" underline="none">
                <Button variant="text" startIcon={<Edit />}> Add shop address </Button>
            </Link>}
    </>
}
