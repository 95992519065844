import { Grid, Typography } from "@mui/material";
import { FC } from "react";
import { Chart } from "react-google-charts";
import { ServiceRecord } from "../../types/ServiceRecord";
import { getFormatteShortdDate } from "../../util/helper";
import { LabelHelper } from "../../util/labelHelper";

export const options = {
    title: "Odo Meter",
    curveType: "function",
};

interface Props {
    data: ServiceRecord[];
}

export const LineChart: FC<Props> = ({ data }) => {

    const dataNew = [...data].reverse();
    const series = [["Date", "Odo meter", {type: 'string', role: 'tooltip', p: {'html': true}}], ...dataNew.map(d => ([getFormatteShortdDate(d.date), d.odo, `<b>${LabelHelper.getServiceTypesLabel(d.categories, true)}</b><br/>${d.desc}<br/>${getFormatteShortdDate(d.date)}<br/>${d.odo} km | miles`]))];

    if (series.length === 1) {
        return <Typography variant="h5" padding={4}>Please add service records to view in chart</Typography>;
    }

    return (
        <Grid sx={{
            backgroundColor: '#FFFFFFCC',
            "&:hover": {
                backgroundColor: "#FFFFFF"
            },
            // height: '80%',
            overflow: 'hidden'
        }}>
            <Chart
                chartType="LineChart"
                data={series}
                options={{
                    title: "Odo Meter (km | miles)",
                    backgroundColor: '#FFFFFF',
                    legend: { position: 'none' },
                    // hAxis: { textPosition: 'none' },
                    pointSize: 4,
                    tooltip: {isHtml: true},
                    colors: ['#15A0C8'],
                }}
            />
        </Grid>
    );
}
