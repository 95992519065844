import {
  CardHeader,
  Box,
  Grid,
} from "@mui/material";
import { FC } from "react";
import { ServiceRecord } from "../../../types/ServiceRecord";
import SpeedIcon from '@mui/icons-material/Speed';
import { getFormattedDate, getFormatteShortdDate, hasUpdateAccess } from "../../../util/helper";
import { LoaderBackdrop } from "../../loader/loaderBackdrop.component";
import { Detail } from "../../shared/Detail";
import { Abc, Person, LocalGasStation, MonetizationOn, Event } from '@mui/icons-material';
import { ImageView } from "../../shared/ImageView";
import { ServiceType } from "../../../selectOptions/serviceTypes";
import { EntityType } from "../../../types/EntityType";
import { LabelHelper } from "../../../util/labelHelper";
import { RecordActions } from "./recordActions";
import { User } from "../../../types/User";
import { DescriptionView } from "../../shared/DescriptionView";

interface Props {
  record: ServiceRecord;
  loading: boolean;
  user: User;
  onDelete: (r: ServiceRecord) => void;
  onEdit: (r: ServiceRecord) => void;
}
export const RecordView: FC<Props> = ({ record, user, loading, onDelete, onEdit }) => {

  if (loading) {
    return <LoaderBackdrop />
  }

  return (
    <Box sx={{ margin: 1 }}>
      <CardHeader title={LabelHelper.getServiceTypesLabel(record.categories)} subheader={getFormattedDate(record.date)} />

      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12} md={6}>
        </Grid>
      </Grid>

      <ImageView entity={record} />
      {hasUpdateAccess(record, user) && <RecordActions r={record} onDelete={onDelete} onEdit={onEdit} />}
      <Grid container>
        {
          record.categories?.includes(ServiceType.FuelTopUp) && <>
            <Grid item xs={12} sm={6} ><Detail name="Cost" value={Number((record.extraProps).cost)} icon={<MonetizationOn color="success" floodColor="primary" />} /></Grid>
            <Grid item xs={12} sm={6} ><Detail name="Volume" value={Number((record.extraProps).volume)} icon={<LocalGasStation color="info" floodColor="primary" />} /></Grid>
          </>
        }
        <Grid item xs={12} sm={6} ><Detail name="Millage" value={record.odo} icon={<SpeedIcon color="primary" floodColor="primary" />} /></Grid>
        <Grid item xs={12} sm={6} ><Detail name="Owner" value={record.User?.name} icon={<Person color="warning" floodColor="primary" />} /></Grid>
        <Grid item xs={12} sm={6} ><Detail name="Last Updated On" value={getFormatteShortdDate(record.updatedAt)} icon={<Event color="success" />} /></Grid>
      </Grid>
      <DescriptionView desc={record.desc} />
    </Box>
  );
};
