import { FC } from "react";
import { useSignInMutation } from "../../../state/api/user.api";
import { setLocalStorage } from "../../../util/localData";
import { SignIn } from "./signIn";
import { LocalStorageKeys } from "../../../enum/localStorageKeys.enum";
import { resetAllCacheStamps } from "../../../util/cacheManager";

export const SignInContainer: FC = () => {
  const [signIn, {isLoading}] = useSignInMutation();

  const handleSignIn = async (args: {
    country: string,
    identifier: string;
    password: string;
  }) => { 
    // resetAllCacheStamps();
    const result: any = await signIn(args);
    if (result.data) {
      setLocalStorage(LocalStorageKeys.country, args.country);
      setLocalStorage(LocalStorageKeys.hasAccount, true);
    } else {
      console.log(result.data);
    }
  };

  return <SignIn loading={isLoading} onSignIn={handleSignIn}/>;
};
