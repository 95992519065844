import { VehicleFilterType } from '../../../types/filters/VehicleFilterType';
import { useFormState } from '../../../hooks/useFormState';
import { fuelTypesWithAny } from '../../../selectOptions/fuelTypes';
import { transmissionTypesWithAny } from '../../../selectOptions/transmissionTypes';
import { vehicleTypesWithAny } from '../../../selectOptions/vehicleTypes';
import { AutoInput, NumberInput } from '../../shared';
import { SelectInput } from '../../shared/SelectInput';
import { CheckCircle, Cancel } from '@mui/icons-material';
import { CustomButton } from '../../shared/CustomButton';
import { FC, useMemo } from 'react';
import { Chip, Dialog, DialogActions, DialogContent, FormGroup, Grid, Stack, Typography } from '@mui/material';
import { User } from '../../../types/User';
import { VEHICLE_FILTER_DEFAULT_VALUES, hiddenFilterParams } from '../../../util/constants';
import { InputGrid } from '../../shared/InputGrid';
import { getActiveFilters } from '../../../util/helper';
import { useBrands } from '../../../hooks/useBrands';
import { SelectOption } from '../../../types/SelectOption';
import { useModels } from '../../../hooks/useModels';
import { LabelHelper } from '../../../util/labelHelper';
import { useUser } from '../../../hooks/useUser';
import { useAreas } from '../../../hooks/useArea';

interface Props {
    open: boolean;
    enabled: boolean;
    user?: User;
    loading: boolean;
    initialState: Partial<VehicleFilterType>;
    onFilterChange: (props: VehicleFilterType) => void;
    onClose: () => void;
}
export const VehicleFilter: FC<Props> = ({ open, initialState, enabled, onFilterChange, onClose, loading }) => {

    const { allAreas, getCountry, getProvince, getAreas } = useAreas();

    const brands = useBrands();
    const models = useModels();
    const user = useUser();

    const { state, changeProperty } = useFormState<VehicleFilterType>({
        ...VEHICLE_FILTER_DEFAULT_VALUES,
        ...initialState,
    });

    const filteredModels = useMemo(() => {
        return models.filter(t => t.meta?.brand === state.brand);
    }, [state.brand, models, brands]);

    const brandsWithAny: SelectOption[] = [{ id: -1, label: "Any" }, ...brands];
    const modelsWithAny: SelectOption[] = [{ id: -1, label: "Any" }, ...filteredModels];

    const activeFilters = useMemo(() => {
        return getActiveFilters<VehicleFilterType>(state, VEHICLE_FILTER_DEFAULT_VALUES);
    }, [state])

    const handleChange = (key: string, value: string | number) => {
        changeProperty(key, value);
    }

    const handleCancel = () => {
        onClose();

    };

    const handleOk = () => {
        onFilterChange(state);
        onClose();
    };

    const handleDelteFilterChip = (key: keyof VehicleFilterType) => {
        const newValue = changeProperty(key, VEHICLE_FILTER_DEFAULT_VALUES[key] ?? '');
        onFilterChange(newValue);
    }

    return (
        <>
            <Grid container display="flex" alignItems="center" justifyContent="end">
                {
                    activeFilters.length > 0 && enabled && <Stack direction="row" flexWrap="wrap">
                        {activeFilters.filter(({ key }) => !hiddenFilterParams.includes(key)).map(f => <Chip sx={{ margin: '2px ' }}
                            key={f.key}
                            label={`${LabelHelper.displayFilterLabel(f.key, f.value, brands, models, allAreas, user.user.country, state.province, state.district)}`}
                            onDelete={() => handleDelteFilterChip(f.key)}
                            size="small"
                            variant="outlined"
                        />)
                        }
                    </Stack>
                }
            </Grid>

            <Dialog
                sx={{ '& .MuiDialog-paper': {} }}
                maxWidth="md"
                open={open}
            >
                <Typography variant="subtitle1" margin={1} marginLeft={3}><b>Filter Vehicles</b></Typography>
                <DialogContent dividers>
                    <FormGroup>
                        <Grid container>
                            <InputGrid>
                                <SelectInput
                                    name="type"
                                    label="Vehicle Type"
                                    options={vehicleTypesWithAny}
                                    disabled={loading}
                                    onChange={changeProperty}
                                    value={state.type}
                                />
                            </InputGrid>

                            <InputGrid>
                                <AutoInput
                                    value={state.brand}
                                    name="brand"
                                    label="Brand"
                                    options={brandsWithAny}
                                    disabled={loading}
                                    onChange={handleChange}
                                />
                            </InputGrid>

                            <InputGrid>
                                <AutoInput
                                    value={state.model}
                                    name="model"
                                    label="Model"
                                    options={modelsWithAny}
                                    disabled={loading}
                                    onChange={handleChange}
                                />
                            </InputGrid>

                            <InputGrid>
                                <SelectInput
                                    value={state.gear}
                                    name="gear"
                                    label="Transmission"
                                    options={transmissionTypesWithAny}
                                    disabled={loading}
                                    onChange={changeProperty}
                                />
                            </InputGrid>

                            <InputGrid>
                                <NumberInput
                                    value={state.yearMin}
                                    name="yearMin"
                                    label="Min Year"
                                    isRequired
                                    disabled={loading}
                                    // error={!Validators.year(vehicleState.yearMin)}
                                    onChange={changeProperty}
                                />
                            </InputGrid>
                            <InputGrid>
                                <NumberInput
                                    value={state.yearMax}
                                    name="yearMax"
                                    label="Max Year"
                                    isRequired
                                    disabled={loading}
                                    // error={!Validators.year(vehicleState.yearMax)}
                                    onChange={changeProperty}
                                />
                            </InputGrid>


                            <InputGrid>
                                <NumberInput
                                    value={state.odoMin}
                                    name="odoMin"
                                    label="Min Millage"
                                    // error={!Validators.odo(vehicleState.odoMin)}
                                    disabled={loading}
                                    onChange={changeProperty}
                                />
                            </InputGrid>

                            <InputGrid>
                                <NumberInput
                                    value={state.odoMax}
                                    name="odoMax"
                                    label="Max Millage"
                                    // error={!Validators.odo(vehicleState.odoMax)}
                                    disabled={loading}
                                    onChange={changeProperty}
                                />
                            </InputGrid>

                            <InputGrid>
                                <NumberInput
                                    value={state.priceMin}
                                    name="priceMin"
                                    label="Min Price"
                                    // error={!Validators.odo(vehicleState.odoMin)}
                                    disabled={loading}
                                    onChange={changeProperty}
                                />
                            </InputGrid>

                            <InputGrid>
                                <NumberInput
                                    value={state.priceMax}
                                    name="priceMax"
                                    label="Max Price"
                                    // error={!Validators.odo(vehicleState.odoMax)}
                                    disabled={loading}
                                    onChange={changeProperty}
                                />
                            </InputGrid>

                            <InputGrid>
                                <SelectInput
                                    value={state.fuel}
                                    name="fuel"
                                    label="Fuel Type"
                                    // error={!Validators.fuelType(vehicleState.fuel)}
                                    options={fuelTypesWithAny}
                                    disabled={loading}
                                    onChange={changeProperty}
                                />
                            </InputGrid>

                            <InputGrid minWidth={4}>
                                <AutoInput
                                    name={'province'}
                                    value={state.province}
                                    label={'Province'}
                                    disabled={false}
                                    options={getCountry(state)?.provinceList ?? []}
                                    onChange={changeProperty}
                                />
                            </InputGrid>
                            <InputGrid minWidth={4}>
                                <AutoInput
                                    name={'district'}
                                    value={state.district}
                                    label={'District'}
                                    disabled={false}
                                    options={getProvince(state)?.districts ?? []}
                                    onChange={changeProperty}
                                />
                            </InputGrid>
                            <InputGrid minWidth={4}>
                                <AutoInput
                                    name={'area'}
                                    value={state.area}
                                    label={'Select Area'}
                                    disabled={false}
                                    options={getAreas(state) ?? []}
                                    onChange={changeProperty}
                                />
                            </InputGrid>

                        </Grid>
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <CustomButton EndIcon={Cancel} onClick={handleCancel} label="Cancel" />
                    <CustomButton EndIcon={CheckCircle} onClick={handleOk} label="Apply" />
                </DialogActions>
            </Dialog>
        </>
    );
}