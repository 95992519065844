import { FC } from "react";
import { EntityEditor } from "./entityEditor"
import { useCreateModelMutation, useReadModelsQuery, useUpdateModelMutation } from "../../../state/api/model.api";
import { Model } from "../../../types/Model";
import { AutoInput, TextInput } from "../../shared";
import { useBrands } from "../../../hooks/useBrands";
import { useFormState } from "../../../hooks/useFormState";
import { InputGrid } from "../../shared/InputGrid";
import { Button, Grid } from "@mui/material";
import { useUser } from "../../../hooks/useUser";

export const ModelsContainer: FC = () => {
    const { user } = useUser();
    const brands = useBrands()

    const { data } = useReadModelsQuery({});

    const [createModel, { isLoading: isLoadingCreate }] = useCreateModelMutation();
    const [updateModel, { isLoading: isLoadingUpdate }] = useUpdateModelMutation();

    const {state, changeProperty} = useFormState({
        brand: 0,
        label: '',
        UserId: user.id
    });

    const models = data as Model[];

    const handleSave = (obj: any) => {
        updateModel(obj)
    }

    const handleAddNew = () => {
        createModel(state);
    }

    return <table style={{ width: '100%' }}>
        <tbody>
            {
                models?.map(m => <tr key={m.id}>
                    <EntityEditor
                        editableProps={['label']}
                        nonEditableProps={['brand', 'id']}
                        entity={m}
                        onSave={handleSave}
                    ></EntityEditor>
                </tr>)
            }
            <tr>
                <td>
                    <Grid container>
                        <InputGrid minWidth={6}>
                            <AutoInput name="brand" value={state.brand} options={brands} label="Brand" disabled={false} onChange={changeProperty} />
                        </InputGrid>
                        <InputGrid minWidth={6}>
                            <TextInput name="label" value={state.label} label="Model" disabled={false} onChange={changeProperty} />
                        </InputGrid>
                        <InputGrid minWidth={4}>
                            <Button variant="outlined" onClick={handleAddNew}>Add New Model</Button>
                        </InputGrid>
                    </Grid>
                </td>
            </tr>
        </tbody>
    </table>;
}