import { Grid } from "@mui/material";
import { FC } from "react";
import { Vehicle } from "../../../types/Vehicle";
import { Header } from "../header/header";
import { LoaderBackdrop } from "../../loader/loaderBackdrop.component";
import { ImageView } from "../../shared/ImageView";
import { ListingType } from "../../../enum/vehicleListing.enum";
import { EntityViewHeader } from "../../shared/EntityViewHeader";
import { LabelHelper } from "../../../util/labelHelper";
import { EntityType } from "../../../types/EntityType";
import { SelectOption } from "../../../types/SelectOption";
import { useBrands } from "../../../hooks/useBrands";

interface Props {
  vehicle: Vehicle;
  loading: boolean;
  loadingFavorite: boolean;
  showFavourite: boolean;
  isFavourite: boolean;
  models: SelectOption[]
  onFavouriteChange: (isFavourite: boolean) => void;
}
export const VehicleView: FC<Props> = ({ loading, vehicle, models, isFavourite, loadingFavorite, showFavourite, onFavouriteChange }) => {

  const brands = useBrands();

  if (loading) {
    return <LoaderBackdrop />;
  }

  if (!vehicle) {
    return <div>Vehicle Not found</div>;
  }

  return (
    <>
      <Grid container>
        <EntityViewHeader
          entityType={EntityType.vehicle}
          title={`${LabelHelper.getBrandLabelEntity(vehicle, brands)} ${LabelHelper.getModelLabelEntity(vehicle, models)}`}
          subTitle={vehicle.listing === ListingType.ListingForMyOwn ? LabelHelper.displayRegNo(vehicle.regNo) : undefined}
          isFavourite={isFavourite}
          showFavourite={showFavourite}
          entity={vehicle}
          onFavoriteChange={onFavouriteChange}
          loadingFavorite={loadingFavorite} />
      </Grid>

      <Grid item xs={12}>
        <Header vehicle={vehicle} />
      </Grid>

      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12} md={6}>
          <ImageView entity={vehicle} />
        </Grid>
      </Grid>
    </>
  );
};
