import { useNavigate } from "react-router-dom";
import { Vehicle } from "../../../types/Vehicle";
import { LoaderLinear } from "../../loader/loaderLinear.component";
import { ModelAdditions } from "./modelAdditions";
import { useGetNewAdditionsToVehicleQuery, useUpdateVehicleMutation } from "../../../state/api/vehicle.api";
import { useGetNewAdditionsToPartsQuery, useUpdatePartMutation } from "../../../state/api/parts.api";
import { useCreateModelMutation } from "../../../state/api/model.api";
import { setKeywordsForVehicle } from "../../vehicle/create/vehicleCreate.util";
import { useBrands } from "../../../hooks/useBrands";
import { useModels } from "../../../hooks/useModels";
import { Part } from "../../../types/Part";
import { setKeywordsOfParts } from "../../parts/create/partCreate.util";
import { Typography } from "@mui/material";

export const ModelAdditionsContainer = () => {

    const { data: vehicles, isLoading: isLoadingVehicles, refetch: refetchVehicles } = useGetNewAdditionsToVehicleQuery('model');
    const { data: parts, isLoading: isLoadingParts, refetch: refetchParts } = useGetNewAdditionsToPartsQuery('model');

    const [createModel, { isLoading: loadingCreateModel }] = useCreateModelMutation();

    const [updateVehicle, { isLoading: loadingUpdateVehicle }] = useUpdateVehicleMutation();
    const [updatePart, { isLoading: loadingUpdatePart }] = useUpdatePartMutation();

    const brands = useBrands();
    const models = useModels();

    const navigate = useNavigate();

    const handleViewVehicle = async (item: Vehicle | Part, isVehicle: boolean) => {
        if (isVehicle) {
            navigate(`/vehicle/${item.id}`);
        } else {
            navigate(`/sparepart/${item.id}`);
        }
    }

    const handleAddModel = async (isVehicle: boolean, item: Vehicle | Part, modelId: number | undefined, modelString: string | undefined) => {

        let newModelId;
        let modelResult: any;

        if (modelString) {
            modelResult = await createModel({
                brand: item.brand,
                label: modelString,
                UserId: item.UserId
            });

            if (modelResult.error) {
                return;
            } else {
                newModelId = modelResult.data.id;
            }
        }

        const newExtraProps = { ...item.extraProps };
        delete newExtraProps.model;

        let response: any;
        if (isVehicle) {
            const newVehicle: Vehicle = {
                ...(item as Vehicle),
                id: item.id,
                model: newModelId ?? modelId,
                extraProps: (newExtraProps)
            };
            const newVehicleWithKeywords = setKeywordsForVehicle(newVehicle, models, brands);
            response = await updateVehicle({
                veh: newVehicleWithKeywords
            });
            refetchVehicles();
        } else {
            const newPart: Part = {
                ...(item as Part),
                id: item.id,
                model: newModelId ?? modelId,
                extraProps: (newExtraProps)
            };
            const newPartWithKeywords = setKeywordsOfParts(newPart, models, brands);
            response = await updatePart({
                part: newPartWithKeywords
            });
            refetchParts();
        }

    }

    if (isLoadingVehicles || isLoadingParts) {
        return <LoaderLinear />
    }

    return (<>
        <Typography variant="subtitle1">Vehicles Model Additions</Typography>
        <ModelAdditions
            isVehicle={true}
            loading={isLoadingVehicles}
            items={vehicles}
            addModel={handleAddModel}
            viewEntity={handleViewVehicle}
        />
        <Typography variant="subtitle1">Parts Model Additions</Typography>
        <ModelAdditions
            isVehicle={false}
            loading={isLoadingParts}
            items={parts}
            addModel={handleAddModel}
            viewEntity={handleViewVehicle}
        />
    </>
    );
}
