import { useSearchParams } from "react-router-dom";
import { SearchParams } from "../../../enum/searchParams.enum";
import { PartsListContainer } from "../list/partsList.container";
import { FC } from "react";
import { ItemViewMode } from "../../../enum/itemViewMode.enum";
import { LocalStorageKeys } from "../../../enum/localStorageKeys.enum";
import { OwnerTypes } from "../../../enum/ownerTypes.enum";

export const PartsListRoute: FC<{
  ownerType: OwnerTypes;
  showBusinessCover: boolean;
  title: string;
  subTitle: string;
  viewMode: ItemViewMode;
  partsFilterKey: LocalStorageKeys;
}> = ({ownerType, partsFilterKey, title, subTitle, viewMode, showBusinessCover }) => {
  const [searchParams] = useSearchParams();
  // this is needed when change the filters we have to reset all the hooks and states to change params like offset and other stuff
  const key = `${searchParams.get(SearchParams.partsFilterObj)} ${searchParams.get(SearchParams.partsFilterKeywords)} ${searchParams.get(SearchParams.partsFilterEnabled)}`;
  
  return <PartsListContainer partsFilterKey={partsFilterKey} viewMode={viewMode} ownerType={ownerType} title={title} subTitle={subTitle} key={key} showBusinessCover={showBusinessCover} />;
};