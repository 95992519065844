import { useNavigate } from "react-router-dom";
import { Vehicle } from "../../../types/Vehicle";
import { LoaderLinear } from "../../loader/loaderLinear.component";
import { BrandAdditions } from "./brandsAdditions";
import { useGetNewAdditionsToVehicleQuery, useUpdateVehicleMutation } from "../../../state/api/vehicle.api";
import { useGetNewAdditionsToPartsQuery, useUpdatePartMutation } from "../../../state/api/parts.api";
import { useCreatebrandMutation } from "../../../state/api/brand.api";
import { setKeywordsForVehicle } from "../../vehicle/create/vehicleCreate.util";
import { useBrands } from "../../../hooks/useBrands";
import { useModels } from "../../../hooks/useModels";
import { Part } from "../../../types/Part";
import { setKeywordsOfParts } from "../../parts/create/partCreate.util";
import { Typography } from "@mui/material";

export const BrandAdditionsContainer = () => {

    const { data: vehicles, isLoading: isLoadingVehicles, refetch: refetchVehicles } = useGetNewAdditionsToVehicleQuery('brand');
    const { data: parts, isLoading: isLoadingParts, refetch: refetchParts } = useGetNewAdditionsToPartsQuery('brand');

    const [createBrand, { isLoading: loadingCreateModel }] = useCreatebrandMutation();

    const [updateVehicle, { isLoading: loadingUpdateVehicle }] = useUpdateVehicleMutation();
    const [updatePart, { isLoading: loadingUpdateParts }] = useUpdatePartMutation();

    const brands = useBrands();
    const models = useModels();

    const navigate = useNavigate();

    const handleViewVehicle = async (v: Vehicle | Part) => {
        navigate(`/vehicle/${v.id}`);
    }

    const handleViewPart = async (p: Vehicle | Part) => {
        navigate(`/sparepart/${p.id}`);
    }

    const handleAddBrand = async (isVehicle: boolean, item: Vehicle | Part, brandId: number | undefined, brandString: string | undefined) => {

        let newBrandId;
        let brandResult: any;

        if (brandString) {
            brandResult = await createBrand({
                label: brandString,
                UserId: item.UserId
            });

            if (brandResult.error) {
                return;
            } else {
                newBrandId = brandResult.data.id;
            }
        }

        const newExtraProps = { ...item.extraProps };
        delete newExtraProps.brand;

        let response: any;
        if (isVehicle) {
            const newVehicle: Vehicle = {
                ...(item as Vehicle),
                brand: newBrandId ?? brandId,
                extraProps: (newExtraProps)
            };
            const newVehicleWithKeywords = setKeywordsForVehicle(newVehicle, models, brands);
            response = await updateVehicle({
                veh: newVehicleWithKeywords
            });
            refetchVehicles();
        } else {
            const newPart: Part = {
                ...(item as Part),
                brand: newBrandId ?? brandId,
                extraProps: (newExtraProps)
            };
            const newPartWithKeywords = setKeywordsOfParts(newPart, models, brands);
            response = await updatePart({
                part: newPartWithKeywords
            });
            refetchParts();
        }
    }

    if (isLoadingParts || isLoadingVehicles) {
        return <LoaderLinear />
    }

    return (
        <>
            <Typography variant="subtitle1">Vehicle Brand Additions</Typography>
            <BrandAdditions
                isVehicle={true}
                loading={isLoadingVehicles}
                items={vehicles}
                addBrand={handleAddBrand}
                viewEntity={handleViewVehicle}
            />
            <Typography variant="subtitle1">Parts Brand Additions</Typography>
            <BrandAdditions
                isVehicle={false}
                loading={isLoadingParts}
                items={parts}
                addBrand={handleAddBrand}
                viewEntity={handleViewPart}
            />
        </>
    );
}