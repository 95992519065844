import { Edit } from "@mui/icons-material";
import { Button, Grid, ImageListItem, Link } from "@mui/material";
import { FC } from "react"

export const CoverImage: FC<{
    src: string | undefined;
    showEditOptions: boolean;
}> = ({ src, showEditOptions }) => {

    return (src ?? !showEditOptions) ? <ImageListItem> { src && <img style={{ 'height': '300px', 'marginBottom': '10px' }} src={src} />}</ImageListItem> :
        <Grid container justifyContent="center">
            <Grid item>
                <Link href="/user/edit" underline="none">
                    <Button variant="text" startIcon={<Edit />}> Add cover image </Button>
                </Link>
            </Grid>
        </Grid>
}