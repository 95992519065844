import { CardMedia } from "@mui/material";
import { FC } from "react";
import { config } from "../../config";
import { ServiceRecord } from "../../types/ServiceRecord";
import { Part } from "../../types/Part";
import { Vehicle } from "../../types/Vehicle";
import { User } from "../../types/User";

interface Props {
    entity: Vehicle | Part | ServiceRecord | User,
    maxHeight?: number;
}
export const ImageCardView: FC<Props> = ({ entity, maxHeight }) => {
    return <CardMedia
        component="img"
        height={maxHeight ?? config.gridImageHeight}
        image={entity.images[config.thumbnailImageIndex] ?? config.defaultVehicleImageUrl}
        alt="Image N/A"
    />
};