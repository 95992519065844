import { useSearchParams } from "react-router-dom";
import { SearchParams } from "../../../enum/searchParams.enum";
import { VehicleListContainer } from "./vehiclesList.container";
import { FC } from "react";
import { ListingType } from "../../../enum/vehicleListing.enum";
import { ItemViewMode } from "../../../enum/itemViewMode.enum";
import { LocalStorageKeys } from "../../../enum/localStorageKeys.enum";
import { OwnerTypes } from "../../../enum/ownerTypes.enum";

export const VehicleListRoute: FC<{
  ownerType: OwnerTypes;
  showBusinessCover: boolean;
  listedFor: ListingType;
  hideFilter?: boolean;
  viewMode: ItemViewMode;
  title: string;
  subTitle: string;
  buttonSubText?: string;
  filterStorageKey: LocalStorageKeys;
}> = ({ownerType, filterStorageKey, listedFor, hideFilter = false, viewMode, title, subTitle, buttonSubText, showBusinessCover}) => {
  const [searchParams] = useSearchParams();
  // this is needed when change the filters we have to reset all the hooks and states to change params like offset and other stuff
  const key = `${ownerType} ${listedFor} ${searchParams.get(SearchParams.vehicleFilterObj)} ${searchParams.get(SearchParams.vehicleFilterKeywords)} ${searchParams.get(SearchParams.vehicleFilterEnabled)}`;
  
  return <VehicleListContainer filterStorageKey={filterStorageKey} viewMode={viewMode} title={title} subTitle={subTitle} buttonSubText={buttonSubText} showBusinessCover={showBusinessCover} ownerType={ownerType} key={key} listedFor={listedFor} hideFilter={hideFilter} />;
};