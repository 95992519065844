import { Button, ButtonGroup, Grid } from "@mui/material";
import { ServiceRecord } from "../../../types/ServiceRecord";
import { FC } from "react";
import { PopupManager } from "../../../util/popupManager";

interface Props {
    r: ServiceRecord;
    onEdit: (r: ServiceRecord) => void;
    onDelete: (r: ServiceRecord) => void;
}
export const RecordActions: FC<Props> = ({ r, onEdit, onDelete }) => {

    const handleDelete = async () => {
        PopupManager.showConfirmBox('Are You Sure?', 'Do you want to delete this service record', 'boolean').then((res) => {
            if(res.confirmed) {
                onDelete(r);
            }
        });
    };

    return (
        <Grid container justifyContent="end" marginTop={1}>
            <ButtonGroup variant="outlined" aria-label="text button group" size="small">
                <Button onClick={() => onEdit(r)}>Edit</Button>
                <Button onClick={() => handleDelete()}>Delete</Button>
            </ButtonGroup>
        </Grid>
    );
}
