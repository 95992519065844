import { Add } from "@mui/icons-material";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FC, useState } from "react";
import { Vehicle } from "../../../types/Vehicle";
import { forcedSignInUser } from "../../../util/helper";
import { CustomButton } from "../../shared/CustomButton";
import { LabelHelper } from "../../../util/labelHelper";
import { TextInput } from "../../shared";
import { Part } from "../../../types/Part";
import { useAreas } from "../../../hooks/useArea";

interface Props {
    isVehicle: boolean;
    items: Array<Vehicle | Part>;
    loading: boolean;
    viewEntity: (i: Vehicle | Part, isVehicle: boolean) => void;
    addArea: (isVehicle: boolean, item: Vehicle | Part, brandId: number | undefined, brandString: string | undefined) => void;
}

export const AreaAdditions: FC<Props> = ({ isVehicle, items, addArea, viewEntity }) => {

    const [editNo, setEditNo] = useState(-1);

    const [area, setArea] = useState('');
    const { allAreas, getAreas } = useAreas();

    const [state, setState] = useState<{
        areaId: number | undefined,
        areaString: string | undefined
    }>({
        areaId: undefined,
        areaString: undefined,
    })

    const changeEditable = (item: Vehicle | Part) => {
        const areaFromExtraProps = item.extraProps.area;
        if (item.id === editNo) {
            // Disable edit
            setEditNo(-1);
            addArea(isVehicle, item, state.areaId, state.areaString);
        } else {
            // Enable edit
            setArea(areaFromExtraProps);
            setEditNo(item.id);
            setState({
                areaId: undefined,
                areaString: areaFromExtraProps
            });
        }
    }

    const handleOnChange = (key: string, value: string | number, fromSuggestions?: boolean) => {
        // Do not change the order below
        if (fromSuggestions) {
            setState({
                areaId: Number(value),
                areaString: undefined,
            });
        } else {
            setState({
                areaId: undefined,
                areaString: `${value}`,
            });
        }
    }

    const handleForceUserLogin = (row: Vehicle | Part) => {
        viewEntity(row, isVehicle);
        forcedSignInUser(row.User);
    }

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Area</TableCell>
                        <TableCell>Country</TableCell>
                        <TableCell>Province</TableCell>
                        <TableCell>District</TableCell>
                        <TableCell>User</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items?.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>
                                {editNo === row.id ? <TextInput
                                    name={"area"}
                                    suggestions={{
                                        initDispVal: row.extraProps.area ?? '',
                                        options: getAreas(row) ?? []
                                    }}
                                    value={area}
                                    label={'Area'}
                                    onChange={handleOnChange}
                                />
                                    :
                                    <div>{LabelHelper.getAreaLabelEntity(row, allAreas)}</div>
                                }
                            </TableCell>
                            <TableCell>{LabelHelper.getCountryLabel(row.country)}</TableCell>
                            <TableCell>{LabelHelper.getProvinceLabel(row.country, row.province)}</TableCell>
                            <TableCell>{LabelHelper.getDistrictLabel(row.country, row.province, row.district)}</TableCell>
                            <TableCell>
                                <Button variant="text" onClick={() => handleForceUserLogin(row)}>{`${row.User?.name}(${row.UserId})`}</Button>
                            </TableCell>
                            <TableCell>
                                <CustomButton EndIcon={Add} size="small" label={editNo === row.id ? "Save" : "Edit"} onClick={() => changeEditable(row)} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}