import { FC, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { config } from "../../../config";
import { usePaginatedData } from "../../../hooks/usePaginatedData";
import { useSearchRecordsQuery } from "../../../state/api/record.api";
import { ServiceRecord } from "../../../types/ServiceRecord";
import { ErrorComponent } from "../../error/error";
import { RecordsGridView } from "./recordsGridView";
import { RecordsTimelineView } from "./recordsTimelineView";
import { RecordViewToggle } from "../viewToggle/recordViewToggle";
import { useGetVehicleByIdQuery } from "../../../state/api/vehicle.api";
import { useAppDispatch } from "../../../state/store";
import { addBreadcrumb } from "../../../state/slice/appSlice";
import { parseEntityId } from "../../../util/helper";
import { useSearchParamsState } from "../../../hooks/useSearchParamsState";
import { ChartContainer } from "../../chart/chart.container";
import { SearchParams } from "../../../enum/searchParams.enum";
import { useUser } from "../../../hooks/useUser";
import { IntroContainer } from "../../intro/Intro.container";
import { recordViewIntroSteps } from "../../../util/introSteps";
import { RecordsFilterType } from "../../../types/filters/RecordsFilterType";
import { LoaderBackdrop } from "../../loader/loaderBackdrop.component";
import { RECORDS_FILTER_DEFAULT_VALUES } from "../../../util/constants";
import { LocalStorageKeys } from "../../../enum/localStorageKeys.enum";
import { Typography } from "@mui/material";
import { RecordVisibility } from "../../../enum/recordVisibility.enum";
import { PopupManager } from "../../../util/popupManager";

export const RecordsListContainer: FC = () => {

  const { vid } = useParams(); // incase if we need to view Records of other owner

  const { user } = useUser();

  const { data: vehicle } = useGetVehicleByIdQuery({ id: parseEntityId(vid) });
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const { searchParamValue: filter, changeSearchParam: setFilter } = useSearchParamsState<string>(SearchParams.recordFilters, JSON.stringify({
    ...RECORDS_FILTER_DEFAULT_VALUES,
    VehicleId: Number(vid),
    visibility: vehicle.UserId === user?.id ? RecordVisibility.private : RecordVisibility.public
  }));

  const { searchParamValue: viewMode, changeSearchParam: setViewMode } = useSearchParamsState<"Timeline" | "Grid">(SearchParams.recordViewMode, "Timeline");

  const navigate = useNavigate();
  const location = useLocation();

  const handleFilterChange = async (filter: RecordsFilterType) => {
    setFilter(JSON.stringify(filter));
  }

  const isOwner = vehicle?.UserId === user?.id;


  const handleSelect = (r: ServiceRecord) => {
    pushToBreadcrumb();
    navigate(`record/${r.id}`);
  };

  const handleAdd = () => {
    pushToBreadcrumb();
    navigate(`record/create`);
  };

  const pushToBreadcrumb = () => {
    if (vehicle) {
      dispatch(addBreadcrumb({ label: vehicle.regNo, path: location.pathname }));
    } else {
      console.error('RecordsListContainer: vehicle not found');
    }
  };

  const handleChangeChartVisibility = async (visibility: boolean) => {
    if (visibility) {
      const result = await PopupManager.showConfirmBox(`Populate chart from ${mergedData?.length} records?`,
        `You can scroll down to add more records to chart.`, "boolean", 'Note: Use the filter button to filter the chart data.')
      if (result.confirmed) {
        setOpen(visibility);
      }
    }
  }

  if (!vid) {
    return <ErrorComponent title="Error" text="Vehicle not found" />;
  }

  if (!vehicle) {
    return <LoaderBackdrop />
  }

  // pagination starts
  const [query1, setQuery] = useState({
    filter: JSON.parse(filter),
    limit: config.pageSize,
    offset: 0,
    isOwner
  });

  const { data, isFetching } = useSearchRecordsQuery(query1);
  const { mergedData, handleLoadMore } = usePaginatedData(data, setQuery);
  // pagination end

  return (
    <>
      <IntroContainer steps={recordViewIntroSteps} storageKey={LocalStorageKeys.introRecordShownCount} />

      {/* for some specific filters, if we hide the record filters when records.lengh === 0 , there after user cannot change the filter to fetch some records since all the filters are hidden.  */}
      {<RecordViewToggle
        vehicle={vehicle}
        viewMode={viewMode}
        onViewModeChange={setViewMode}
        filter={JSON.parse(filter)}
        onFilterChange={handleFilterChange}
        showChart={open}
        onChartVisibilityChange={handleChangeChartVisibility}
      />}

      {mergedData?.length === 0 && <Typography color="Highlight" margin={2} variant="subtitle2">No service records are available.</Typography>}

      {viewMode === "Timeline" ? (
        <RecordsTimelineView
          vehicle={vehicle}
          isOwner={isOwner}
          onAdd={handleAdd}
          hasMore={data?.length > 0}
          onSelect={handleSelect}
          loading={isFetching}
          items={mergedData}
          onLoadMore={handleLoadMore}
        />
      ) : (
        <RecordsGridView
          vehicle={vehicle}
          isOwner={isOwner}
          onAdd={handleAdd}
          hasMore={data?.length > 0}
          onSelect={handleSelect}
          loading={isFetching}
          items={mergedData}
          onLoadMore={handleLoadMore}
        />
      )}


      {open && <ChartContainer open={open} vehicle={vehicle} onClose={() => setOpen(false)} items={mergedData} />}
    </>
  );
};
