import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FC } from "react";
import { withScroller } from "../../../hoc/withScroller";
import { ServiceRecord } from "../../../types/ServiceRecord";
import { getFormatteShortdDate } from "../../../util/helper";
import { CustomButton } from "../../shared/CustomButton";
import { DirectionsCarFilled, Grading } from "@mui/icons-material";

interface Props {

    hasMore: boolean;
    items: ServiceRecord[];
    loading: boolean;
    onLoadMore: (nextLimit: number) => void;
    onView: (vid: number, recordId: number, isVehicle: boolean) => void;
}

const RecordsAdminComp: FC<Props> = ({ items, onView }) => {

    // return  <div> Table </div>
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Vehicle Id</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Millage</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>ImageCount</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>{row.VehicleId}</TableCell>
                            <TableCell>{getFormatteShortdDate(row.date)}</TableCell>
                            <TableCell>{row.categories.toString()}</TableCell>
                            <TableCell>{row.odo}</TableCell>
                            <TableCell>{row.desc}</TableCell>
                            <TableCell>{JSON.stringify(row.images)}</TableCell>
                            <TableCell>
                                <CustomButton EndIcon={Grading} size="small" label="View Record" onClick={() => onView(row.VehicleId, row.id, false)} />
                                <CustomButton EndIcon={DirectionsCarFilled} size="small" label="View Vehicle" onClick={() => onView(row.VehicleId, row.id, true)} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
export const RecordsAdmin = withScroller(RecordsAdminComp);