import { useReadModelsQuery } from "../state/api/model.api";
import { SelectOption } from "../types/SelectOption";
import { Model } from "../types/Model";

export const useModels = () => {
  const { data } = useReadModelsQuery({});
  const models = data as Model[];
  const casted: SelectOption[] = models ? models.map(t => ({
    id: t.id,
    label: t.label,
    meta: t
  })) : [];

  const result = [
    ...casted,
  ]
  return result;
};
