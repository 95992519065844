import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Timeline, ViewQuilt } from "@mui/icons-material";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { config } from "../../../config";
import { UserType } from "../../../enum/user.type";
import { usePaginatedData } from "../../../hooks/usePaginatedData";
import { VehicleTimelineView } from "./vehicleTimelineView";
import { useSearchParamsState } from "../../../hooks/useSearchParamsState";
import { SearchParams } from "../../../enum/searchParams.enum";
import { useUser } from "../../../hooks/useUser";
import { SearchInput } from "./SearchInput";
import { useSearchVehiclesQuery } from "../../../state/api/vehicle.api";
import { PageHeader } from "../../shared/PageHeader";
import { VehicleGridView } from "../view/vehicleGridView";
import { ItemViewMode } from "../../../enum/itemViewMode.enum";
import { OwnerTypes } from "../../../enum/ownerTypes.enum";
import { useModels } from "../../../hooks/useModels";

interface Props {
  searchKey: string;
}
export const VehicleSearchContainer: FC<Props> = ({ searchKey }) => {
  const { user } = useUser();
  const models = useModels();
  const navigate = useNavigate();
  const { searchParamValue: viewMode, changeSearchParam: setViewMode } = useSearchParamsState<"Timeline" | "Grid">(SearchParams.vehicleViewMode, "Grid");

  const handleSelect = (vid: number) => {
    const path = user?.type === UserType.ServiceCenter ? `/vehicle/${vid}/record/create` : `/vehicle/${vid}?recordsFetch=Full`
    navigate(path);
  };

  // Pagination starts
  const [query, setQuery] = useState({
    keywords: searchKey,
    filter: {},
    limit: config.pageSize,
    offset: 0,
    ownerType: OwnerTypes.Any
  });
  const { data, isFetching } = useSearchVehiclesQuery(query);
  const { mergedData, handleLoadMore } = usePaginatedData(data, setQuery);
  // Pagination ends

  const handleSearch = (key: string) => {
    navigate(`/vehicle/search?key=${key}`);
  };

  return (
    <> <PageHeader
      header="Find History Of Vehicles"
      subHeader="Enter the Reg.No. or Chassis No. and find the service records added by previous owners"
      showEditOptions={false}
    />
      <Grid container>

        <SearchInput onSearch={handleSearch} searchKey={searchKey} />
        <Grid item sm={12} container justifyContent="space-between">
          <Grid item>
            <ToggleButtonGroup
              color="primary"
              value={viewMode}
              exclusive
              aria-label="Platform"
            >
              <ToggleButton
                value="Timeline"
                onClick={() => setViewMode("Timeline")}
              >
                <Timeline />
              </ToggleButton>
              <ToggleButton value="Grid" onClick={() => setViewMode("Grid")}>
                <ViewQuilt />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>

        <Grid item xs={12} >
          {
            viewMode === "Grid" ? <VehicleGridView
              models={models}
              viewMode={ItemViewMode.grid}
              hasMore={data?.length > 0}
              onSelect={handleSelect}
              loading={isFetching}
              items={mergedData}
              onLoadMore={handleLoadMore}
            /> :
              <VehicleTimelineView
                hasMore={data?.length > 0}
                onSelect={handleSelect}
                loading={isFetching}
                items={mergedData}
                onLoadMore={handleLoadMore}
              />
          }
        </Grid>
      </Grid>
    </>
  );
};
