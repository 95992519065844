import { FC, useMemo, useState } from "react";
import { useFormState } from "../../../hooks/useFormState";
import { NumberInput, TextInput } from "../../shared";
import { ImageInput } from "../../shared/ImageInput";
import { Validators } from "../../../util/validators";
import { User } from "../../../types/User";
import { FormWrapper } from "../../shared/FormWrapper";
import { Grid } from "@mui/material";
import { config } from "../../../config";
import { Part } from "../../../types/Part";
import { TreeSelect } from "../../treeView/TreeView";
import { partsTypes } from "../../../selectOptions/partsTypes";
import { vehicleTypes } from "../../../selectOptions/vehicleTypes";
import { INode } from "../../../types/INode";
import { Node } from "../../../types/Node";
import { CustomButton } from "../../shared/CustomButton";
import { Upload } from "@mui/icons-material";
import { InputGrid } from "../../shared/InputGrid";
import { MultipleSelectChip } from "../../shared/MultipleSelectChip";
import { sanitizePart } from "./partCreate.util";
import { PhoneInput } from "../../shared/PhoneInput";
import { defaultPart } from "../../../defaultValues/defaultPart";
import { LocationInput } from "../../shared/LocationInput";
import { appendQueryParam } from "../../../util/helper";
import { generateNodeArrayFromCategories } from "../../../util/nodeHelper";
import { useModels } from "../../../hooks/useModels";
import { useBrands } from "../../../hooks/useBrands";

interface Props {
  user: User;
  loading: boolean;
  initialState?: Partial<Part>;
  onSave: (p: Part, image: Record<number, Blob | null>) => void;
}
export const PartCreate: FC<Props> = ({
  loading,
  user,
  initialState,
  onSave,
}) => {
  const models = useModels();
  const brands = useBrands();

  const [images, setImages] = useState<Record<number, Blob | null>>({});
  const [partNodesArr, setPartNodesArr] = useState<INode[]>(partsTypes);

  const onImageChange = (img: Blob | null, index: number) => {
    setImages(prev => {
      const next = { ...prev };
      next[index] = img;
      return next;
    });
  };

  const handleSave = async (p: Part, image: Record<number, Blob | null>) => {
    await onSave(sanitizePart(state, models, brands), images);
  }

  const {state, changeProperty, changeExtraProperty, changeBulk, changeBulkExtraProperty, changeWithExtraProperty} = useFormState<Part>({
    ...defaultPart(user),
    ...initialState
  });

  const handleSeletionChange = (e: Node[], values: string[]) => {
    changeProperty('categories', values);
    // const selectedNodeLabels = e.reduce((a: string[], b) => ([...a, ...b.getSelectedLabels()]), []);
    // setSelectedNodeLabels(selectedNodeLabels);
  }

  const filteredModels = useMemo(() => {
    return models.filter(t => t.meta?.brand === state.brand);
  }, [state.brand, models, brands]);

  const filteredPartTypesNew: INode[] = generateNodeArrayFromCategories(partNodesArr, state.vehicleTypes, state.categories);
  const imagesObj = state.images;

  return (

    <FormWrapper>
      <Grid container>

        <InputGrid minWidth={12}>
          <TextInput
            value={state.title}
            name="title"
            label="Title"
            error={!Validators.title(state.title)}
            disabled={loading}
            onChange={changeProperty}
          />
        </InputGrid>

        <InputGrid minWidth={12}>
          <TextInput
            suggestions={{
              initDispVal: state.extraProps.brand ?? '',
              options: brands,
              // onSelect: handleOnSelect
            }}
            value={state.brand}
            name="brand"
            label="Manufacturer Name"
            error={loading || !Validators.brand(state, brands)}
            isRequired
            disabled={loading}
            onChange={changeWithExtraProperty}
          />
        </InputGrid>

        <InputGrid minWidth={12}>
          <MultipleSelectChip
            error={!Validators.vehicleTypes(state.vehicleTypes)}
            name="vehicleTypes" value={state.vehicleTypes}
            label="Compatible Vehicles Categories"
            options={vehicleTypes}
            onChange={changeProperty} />
        </InputGrid>

        <InputGrid minWidth={6}>
          <TextInput
            suggestions={{
              initDispVal: state.extraProps.model ?? '',
              options: filteredModels,
              // onSelect: handleOnSelect
            }}
            value={state.model}
            name="model"
            label="Model"
            error={!Validators.model(state, models)}
            isRequired
            disabled={loading || !Validators.brand(state, brands)} // model needs to be disabled until a valid brand is selected
            onChange={changeWithExtraProperty}
          />
        </InputGrid>

        <InputGrid>
          <NumberInput
            value={state.yearMin === config.yMin ? undefined : state.yearMin}
            name="yearMin"
            label="Min Year"
            disabled={loading}
            onChange={changeProperty}
          />
        </InputGrid>

        <InputGrid>
          <NumberInput
            value={state.yearMax === config.yMax ? undefined : state.yearMax}
            name="yearMax"
            label="Max Year"
            disabled={loading}
            onChange={changeProperty}
          />
        </InputGrid>

        <Grid container justifyContent="center" marginTop={4}>
          <TreeSelect disableAutoSelectChildren setNodesArr={setPartNodesArr} disabled={loading} error={state.categories.length === 0} isSingleSelection={false} userNodeArr={filteredPartTypesNew} onSelectionChange={handleSeletionChange} />
        </Grid>

        <Grid container spacing={2} padding={2}>
          {
            Array.from(Array(config.recordImageCount)).map((_, i) => {
              return <Grid item xs={6} key={i}><ImageInput
                index={i}
                disabled={loading}
                defaultImageUrl={appendQueryParam(imagesObj[i], 'updatedAt', state.updatedAt.toString()) ?? config.defaultVehicleImageUrl}
                onImageSelected={onImageChange}
              /></Grid>;
            })
          }
        </Grid>

        <InputGrid minWidth={12}>
          <TextInput
            value={state.desc ?? ''}
            name="desc"
            label="Description"
            // error={!Validators.description(partState.desc)}
            disabled={loading}
            multiline
            onChange={changeProperty}
          />
        </InputGrid>

        <InputGrid minWidth={4}>
          <NumberInput
            value={state.price ?? 0}
            name="price"
            label="Price"
            error={!Validators.numberPositiveOrZero(state.price)}
            disabled={loading}
            onChange={changeProperty}
          />
        </InputGrid>  
 
        <InputGrid minWidth={4}>
          <PhoneInput
            country={state.country}
            value={state.phone}
            name="phone"
            label="Phone Number"
            error={!Validators.validatePhone(state.phone, state.country)}
            onChange={changeProperty}
            disabled={loading}
          />
        </InputGrid>  

        <InputGrid minWidth={12}>
          <LocationInput
            state={state}
            country={user.country}
            disabled={loading}
            changeBulk={changeBulk}
            changeWithExtraProperty={changeWithExtraProperty}
          />
        </InputGrid>

        <Grid marginTop={2} container justifyContent="center">
          <CustomButton
            EndIcon={Upload}
            label="Save Spare Part"
            disabled={!Validators.validPart(state, brands, models)}
            onClick={() => handleSave(state, images)}
            loading={loading}
          />
        </Grid>
      </Grid>
    </FormWrapper>
  );
};


