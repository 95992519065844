import { VehicleFilterType } from "../../../types/filters/VehicleFilterType";
import { VehicleFilter } from "../filter/VehicleFilter";
import { useSearchVehiclesQuery } from "../../../state/api/vehicle.api";
import { useNavigate } from "react-router-dom";
import { usePaginatedData } from "../../../hooks/usePaginatedData";
import { FC, useState } from "react";
import { config } from "../../../config";
import { SearchParams } from "../../../enum/searchParams.enum";
import { useSearchParamsState } from "../../../hooks/useSearchParamsState";
import { PageHeader } from "../../shared/PageHeader";
import { SearchBox } from "../../shared/SearchBox";
import { Grid, ImageListItem, Link } from "@mui/material";
import { ListingType } from "../../../enum/vehicleListing.enum";
import { VehicleGridView } from "../view/vehicleGridView";
import { getLocalStorage, setLocalStorage } from "../../../util/localData";
import { LocalStorageKeys } from "../../../enum/localStorageKeys.enum";
import { ItemViewMode } from "../../../enum/itemViewMode.enum";
import { OwnerTypes } from "../../../enum/ownerTypes.enum";
import { useUserForEntityFetch } from "../../../hooks/useUserForEntityFetch";
import { useGetUserInfoQuery } from "../../../state/api/user.api";
import { PhoneDial } from "../../shared/PhoneDial";
import { useModels } from "../../../hooks/useModels";
import { copyToClipboard } from "../../../util/helper";
import { UserImageType } from "../../../enum/user.type";
import { PopupManager } from "../../../util/popupManager";
import { CoverImage } from "../../shared/CoverImage";

// When filters are changed, this page is fully reloaded by market.router.tsx. So we don't have to manual reset the states like offset
export const VehicleListContainer: FC<{
    ownerType: OwnerTypes;
    showBusinessCover: boolean;
    listedFor: ListingType;
    hideFilter: boolean;
    title: string;
    subTitle: string;
    buttonSubText?: string;
    viewMode: ItemViewMode;
    filterStorageKey: LocalStorageKeys
}> = ({ title, subTitle, showBusinessCover, ownerType, filterStorageKey, listedFor, hideFilter, buttonSubText, viewMode }) => {

    const navigate = useNavigate();
    const models = useModels();
    const [openFilter, setOpenFilter] = useState(false);

    const emptyFilter = `{"listing":${listedFor}}`;
    const savedFilter = getLocalStorage(filterStorageKey, emptyFilter);

    const ownerIdOfVehicles = useUserForEntityFetch(ownerType);

    // const ownerInfo = useGetUserInfoQuery({ id: ownerType === OwnerTypes.ProvidedAsParam ? Number(ownerIdOfVehicles) : -1 })
     const ownerInfo = useGetUserInfoQuery({ id: ownerIdOfVehicles});

    let dispTitle = title;
    let dispSubtitle = subTitle;

    if (showBusinessCover && ownerInfo) {
        dispTitle = ownerInfo?.data?.shopName ?? `Vehicles for sale by ${ownerInfo?.data?.name}`;
        dispSubtitle = ownerInfo?.data?.shopAddress ?? `Try browse vehicles with filters`
    }

    const initialFilterWithUserIdAndListingType = {
        UserId: ownerIdOfVehicles,
        listing: listedFor
    }

    // these params should be included at market.route page to reset the whole component when these params are changed
    const { searchParamValue: vehicleFilterObjFromUrl, changeSearchParam: setMarketFilterObj } = useSearchParamsState<string>(SearchParams.vehicleFilterObj, JSON.stringify(initialFilterWithUserIdAndListingType));
    const { searchParamValue: vehicleFilterKeywords, changeSearchParam: setMarketFilterKeywords } = useSearchParamsState<string>(SearchParams.vehicleFilterKeywords, '');
    const { searchParamValue: vehicleFilterEnabled, changeSearchParam: setMarketFilterEnabled } = useSearchParamsState<string>(SearchParams.vehicleFilterEnabled, 'true');

    const vehiclesFilterObj = JSON.parse(vehicleFilterObjFromUrl === emptyFilter ? savedFilter : vehicleFilterObjFromUrl);

    // Pagination starts
    const [query, setQuery] = useState({
        keywords: vehicleFilterKeywords,
        filter: vehicleFilterEnabled === 'true' ? vehiclesFilterObj : initialFilterWithUserIdAndListingType,
        limit: config.pageSize,
        offset: 0,
        ownerType
    });
    const { data, isFetching } = useSearchVehiclesQuery(query);
    const { mergedData, handleLoadMore } = usePaginatedData(data, setQuery);
    // Pagination ends

    const handleFilterChange = async (filter: VehicleFilterType) => {
        const filterObj = JSON.stringify(filter);
        setMarketFilterObj(filterObj);
        setLocalStorage(filterStorageKey, filterObj);
    }

    const handleFilterEnable = (enabled: boolean) => {
        setMarketFilterEnabled(enabled ? 'true' : 'false');
    }

    const handleSearchChange = (keyword: string) => {
        const lowercaseKw = keyword.toLowerCase();
        setMarketFilterKeywords(lowercaseKw);
    }

    const handleSelect = (vid: number) => {
        const path = `/vehicle/${vid}`;
        navigate(path);
    };

    const handleAddVehicle = () => {
        navigate(`/vehicle/create?vehicleListing=${listedFor}`);
    }

    const onShare = () => {
        const link = `${window.location.origin}/vehicle/shop/${ownerIdOfVehicles}`;
        void copyToClipboard(link);
        PopupManager.showSuccessToast(`Link copied! ${link}`);
    }

    const coverImage = ownerInfo.data?.images[UserImageType.BusinessCover];

    return <Grid>
        {ownerInfo?.data && <Grid container justifyContent="end" alignItems="center">
            <Link href='#' onClick={onShare} variant="body2" margin={1}>
                Share This Page
            </Link>
            <Grid item><PhoneDial phone={ownerInfo?.data?.shopPhone} /></Grid>
        </Grid>}

        <PageHeader header={dispTitle} subHeader={dispSubtitle} showEditOptions={ownerType === OwnerTypes.My} />

        {showBusinessCover && <CoverImage src={coverImage} showEditOptions={ownerType === OwnerTypes.My} />}

        {!hideFilter && <Grid container justifyContent='end' alignItems='center'>
            <Grid item>
                <VehicleFilter enabled={vehicleFilterEnabled === 'true'} initialState={vehiclesFilterObj} loading={false} open={openFilter} onFilterChange={handleFilterChange} onClose={() => setOpenFilter(false)} />
            </Grid>
            <Grid item>
                <SearchBox placeHolder="Search Vehicles" filterEnabled={vehicleFilterEnabled === 'true'} onFilterEnable={handleFilterEnable} onChange={handleSearchChange} value={vehicleFilterKeywords} onFilterOpen={() => setOpenFilter(true)} />
            </Grid>
        </Grid>}

        <VehicleGridView
            models={models}
            hasMore={data?.length > 0}
            onSelect={handleSelect}
            loading={isFetching}
            items={mergedData}
            onLoadMore={handleLoadMore}
            onAdd={handleAddVehicle}
            viewMode={viewMode}
            buttonSubText={buttonSubText}
        />
    </Grid>
}