import { CardMedia, Grid } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { config } from "../../config";
import { ServiceRecord } from "../../types/ServiceRecord";
import { Part } from "../../types/Part";
import { Vehicle } from "../../types/Vehicle";
import { User } from "../../types/User";
import { appendQueryParam } from "../../util/helper";

interface Props {
    entity: Vehicle | Part | ServiceRecord | User
    maxHeight?: number;
}
export const ImageView: FC<Props> = ({ entity, maxHeight }) => {

    const [imgUrls, setImageUrls] = useState<string[]>([]);
    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        try {
            const urlArr = [];
            const imgObj: Record<string, string> = entity.images;

            Object.entries(imgObj).forEach(([key, value]) => {
                if (value !== null && value !== undefined && key !== `${config.thumbnailImageIndex}`) {
                    urlArr.push(appendQueryParam(`${value}`, 'updatedAt', entity.updatedAt.toString()));
                }
            });

            if (urlArr.length === 0) {
                urlArr.push(config.defaultVehicleImageUrl);
            }

            setImageUrls(urlArr);
        } catch (e) {
            console.log(e);
        }
    }, [entity]);

    const handleVisibilityChange = (visible: boolean) => {
        setOpen(visible);
    }

    const drawImages = isOpen ? imgUrls : [imgUrls[0]];

    return (
        <Grid margin="auto">
            <PhotoProvider onVisibleChange={handleVisibilityChange}>
                {drawImages.map((url, index) =>
                    <PhotoView src={url} key={url ?? index} >
                        <CardMedia
                            component="img"
                            height={250}
                            image={url}
                            alt="Image N/A"
                        />
                    </PhotoView>
                )}
            </PhotoProvider>
        </Grid>
    )
};