import { Add } from "@mui/icons-material";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FC, useState } from "react";
import { Vehicle } from "../../../types/Vehicle";
import { forcedSignInUser } from "../../../util/helper";
import { CustomButton } from "../../shared/CustomButton";
import { LabelHelper } from "../../../util/labelHelper";
import { TextInput } from "../../shared";
import { useBrands } from "../../../hooks/useBrands";
import { useModels } from "../../../hooks/useModels";
import { Part } from "../../../types/Part";

interface Props {
    isVehicle: boolean;
    items: Vehicle[];
    loading: boolean;
    viewEntity: (i: Vehicle | Part, isVehicle: boolean) => void;
    addBrand: (isVehicle: boolean, item: Vehicle | Part, brandId: number | undefined, brandString: string | undefined) => void;
}

export const BrandAdditions: FC<Props> = ({ isVehicle, items, addBrand, viewEntity }) => {

    const [editNo, setEditNo] = useState(-1);

    const [brand, setBrand] = useState('');
    const brands = useBrands();
    const models = useModels();

    const [state, setState] = useState<{
        brandId: number | undefined,
        brandString: string | undefined
    }>({
        brandId: undefined,
        brandString: undefined,
    })

    const enableEdit = (item: Vehicle | Part) => {
        const brand = item.extraProps.brand;
        if (item.id === editNo) {
            setEditNo(-1);
            addBrand(isVehicle, item, state.brandId, state.brandString);

        } else {
            setBrand(brand);
            setEditNo(item.id);
            setState({
                brandId: undefined,
                brandString: item.extraProps.brand
            });
        }
    }

    const handleOnChange = (key: string, value: string | number, fromSuggestions?: boolean) => {
        // Do not change the order below
        if (fromSuggestions) {
            setState({
                brandId: Number(value),
                brandString: undefined,
            });
        } else {
            setState({
                brandId: undefined,
                brandString: `${value}`,
            });
        }
    }

    const handleForceUserLogin = (row: Vehicle | Part) => {
        viewEntity(row, isVehicle);
        forcedSignInUser(row.User);
    }

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Brand</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Model</TableCell>
                        <TableCell>User</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items?.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>
                                {editNo === row.id ? <TextInput
                                    name={"brand"}
                                    suggestions={{
                                        initDispVal: row.extraProps.brand ?? '',
                                        options: brands,
                                        // onSelect: handleOnSelect
                                    }}
                                    value={brand}
                                    label={'Brand'}
                                    onChange={handleOnChange}
                                />
                                    :
                                    <div>{LabelHelper.getBrandLabelEntity(row, brands)}</div>
                                }
                            </TableCell>
                            <TableCell>{LabelHelper.getVehicleTypeLabel(row.type)}</TableCell>
                            <TableCell>{LabelHelper.getModelLabelEntity(row, models)}</TableCell>
                            <TableCell>
                                <Button variant="text" onClick={() => handleForceUserLogin(row)}>{`${row.User?.name}(${row.UserId})`}</Button>
                            </TableCell>
                            <TableCell>
                                <CustomButton EndIcon={Add} size="small" label={editNo === row.id ? "Save" : "Edit"} onClick={() => enableEdit(row)} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}