import { SelectOption } from "../types/SelectOption";
import {
  DonutSmall,
  LocalGasStation,
  MonitorHeart,
  Air,
  AllInclusive,
  Dangerous,
  Pentagon,
  Grading,
  Cable,
  LensBlur,
  Settings,
  EventSeat,
  Soap}
 from "@mui/icons-material";

export enum ServiceType {
  FuelTopUp = 100
}

export const serviceTypes: SelectOption[] = [
  { label: "Fuel Topup", shortLabel: "Fuel", id: ServiceType.FuelTopUp, Icon: <LocalGasStation />, color: "success" }, // this id is used in RecordCreate component, validator
  { label: "Body Wash", shortLabel: "Wash", id: 150, Icon: <Soap />, color: "secondary" },

  { label: "Engine/ Motor/ Battery", shortLabel: "Engi. Bat", id: 200, Icon: <MonitorHeart />, color: "primary"},
  { label: "Transmission/ Drive Train", shortLabel: "Gear.", id: 250, Icon: <Settings />, color: "error" },
  { label: "Suspension/ Steering", shortLabel: "Steer", id: 300, Icon: <AllInclusive />, color: "secondary" },

  { label: "Break System", shortLabel: "Break", id: 350, Icon: <Dangerous />, color: "primary" },
  { label: "Electrical/ Lighting", shortLabel: "Elec.", id: 400, Icon: <Cable />, color: "success" },

  { label: "Tyres/ Wheels", shortLabel: "Wheels", id: 450, Icon: <DonutSmall />, color: "error" },

  { label: "Air Conditioning/ Heating", shortLabel: "AC/Heat", id: 500, Icon: <Air />, color: "success" },

  { label: "Frame/ Body Repair", shortLabel: "Body", id: 550, Icon: <Pentagon />, color: "primary" },
  { label: "Entertainment System", shortLabel: "Entertainment", id: 600, Icon: <EventSeat />, color: "primary" },
  { label: "Interior", shortLabel: "Interior", id: 650, Icon: <EventSeat />, color: "primary" },

  { label: "License/ Insurance", shortLabel: "Regist.", id: 700, Icon: <Grading />, color: "primary" },
  { label: "Other", shortLabel: "Other", id: 999, Icon: <LensBlur />, color: "success" }, 
];
