import { FC } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Storefront, PostAdd } from "@mui/icons-material";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { AvatarBadge } from "./avatarBadge";
import { User } from "../../types/User";
import { BottomBar } from "./bottomBar";
import { Page } from "../../types/Page";
import { SidePanel } from "./sidePanel";
import { moreMenu } from "../../hooks/useNavigationMenu";
import { UserUpdateContainer } from "../user/update/userUpdate.container";

interface Props {
  pages: Page[];
  selectedPage: number;
  searchKey: string;
  user: User | null | undefined;
  showSidePanel: boolean,
  // onUpdateProfile: (user: User) => void;
  onMenuClick: (path: string) => void;
  // onSearch: (key: string) => void;
  onLogout: () => void;
  onLogin: () => void;
  onNavigate: (path: string) => void;
  onToggleSidePanel: (visibility: boolean) => void;
  isLoginVisible: boolean;
}
export const TopBar: FC<Props> = ({
  user,
  pages,
  selectedPage,
  showSidePanel,
  // searchKey,
  onMenuClick,
  onToggleSidePanel,
  onLogout,
  onLogin,
  // onSearch,
  onNavigate,
  // onUpdateProfile,
  isLoginVisible
}) => {

  const actions = [
    { icon: <PostAdd />, name: "Service Record", path: `/vehicle/-1/record/create` },
    // { icon: <Construction />, name: "Sell Parts", path: `/sparepart/create` },
    // { icon: <DirectionsCar />, name: "Sell Vehicle", path: `/vehicle/create?${SearchParams.vehicleListing}=${ListingType.ListingForSale}` },
    // { icon: <DirectionsCar />, name: "Own Vehicle", path: `/vehicle/create?${SearchParams.vehicleListing}=${ListingType.ListingForMyOwn}` },
  ];

  return (
    <>
      <SidePanel showPanel={showSidePanel} onVisibilityChange={onToggleSidePanel} />
      {user?.country === null && <UserUpdateContainer onlyCountrySelectorPopup />}
      <AppBar position="static" sx={{
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      }}>
        <Container>
          <Toolbar disableGutters>
            <Typography sx={{ cursor: 'pointer', marginRight: 1, typography: { sm: 'h4', xs: 'h6' }, float: 'left' }} >
              <b onClick={() => onNavigate(``)}>
                <i>OdoCert </i>
              </b>
            </Typography>

            <Box sx={{ marginLeft: 2, flexGrow: 1, display: { xs: "flex" } }}>
              {pages.map(({ Icon, label, path }, index) => (
                <Button
                  key={label}
                  onClick={() => onMenuClick(path)}
                  sx={{ mx: 0, my: 2, gridGap: 5, color: "white", placeItems: 'center', fontWeight: 'bold', borderColor: 'white', display: { xs: "none", md: "flex" } }}
                >
                  {Icon} {` ${label}`}
                </Button>
              ))}

              <Button
                onClick={() => onToggleSidePanel(true)}
                sx={{ mx: 0, my: 2, gridGap: 5, color: "white", placeItems: 'center', fontWeight: 'bold', borderColor: 'white', display: { xs: "none", md: "flex" } }}
              >
                <Storefront />
                {moreMenu}
              </Button>

            </Box>

            <AvatarBadge onLogout={onLogout} onLogin={onLogin} user={user} onNavigate={onNavigate} isLoginVisible={isLoginVisible} onUpdateProfile={(user: User) => onNavigate(`/user/edit`)} />

          </Toolbar>
        </Container>
      </AppBar>



      <BottomBar pages={pages} selectedPage={selectedPage} onClick={onMenuClick} showPanel={showSidePanel} onVisibilityChange={onToggleSidePanel} />

      {user && <div>
        <SpeedDial
          direction="up"
          ariaLabel="SpeedDial basic example"
          sx={{ position: "fixed", bottom: 56, right: 16 }}
          icon={<SpeedDialIcon className="speed-dial" />}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              onClick={() => onMenuClick(action.path)}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipOpen
            ></SpeedDialAction>
          ))}
        </SpeedDial>
      </div>}
    </>
  );
};
