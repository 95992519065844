import { Grid, ImageListItem, Link } from "@mui/material";
import { useSearchPartsQuery } from "../../../state/api/parts.api";
import { PartsList } from "./partsList";
import { FC, useState } from "react";
import { Part } from "../../../types/Part";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "../../shared/PageHeader";
import { useSearchParamsState } from "../../../hooks/useSearchParamsState";
import { SearchParams } from "../../../enum/searchParams.enum";
import { config } from "../../../config";
import { usePaginatedData } from "../../../hooks/usePaginatedData";
import { SearchBox } from "../../shared/SearchBox";
import { PartsFilter } from "../filter/PartsFilter";
import { PartsFilterType } from "../../../types/filters/PartsFilterType";
import { getLocalStorage, setLocalStorage } from "../../../util/localData";
import { LocalStorageKeys } from "../../../enum/localStorageKeys.enum";
import { ItemViewMode } from "../../../enum/itemViewMode.enum";
import { OwnerTypes } from "../../../enum/ownerTypes.enum";
import { useUserForEntityFetch } from "../../../hooks/useUserForEntityFetch";
import { useGetUserInfoQuery } from "../../../state/api/user.api";
import { PhoneDial } from "../../shared/PhoneDial";
import { copyToClipboard } from "../../../util/helper";
import { UserImageType } from "../../../enum/user.type";
import { PopupManager } from "../../../util/popupManager";
import { CoverImage } from "../../shared/CoverImage";

export const PartsListContainer: FC<{
  ownerType: OwnerTypes;
  showBusinessCover: boolean;
  title: string;
  subTitle: string;
  viewMode: ItemViewMode;
  partsFilterKey: LocalStorageKeys;
}> = ({ ownerType, title, subTitle, viewMode, partsFilterKey, showBusinessCover }) => {

  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);

  const emptyFilter = `{}`;
  const savedFilter = getLocalStorage(partsFilterKey, emptyFilter);

  const ownerIdOfParts = useUserForEntityFetch(ownerType);

  // const ownerInfo = useGetUserInfoQuery({ id: ownerType === OwnerTypes.ProvidedAsParam ? Number(UserId) : -1 });
  const ownerInfo = useGetUserInfoQuery({ id: ownerIdOfParts });

  let dispTitle = title;
  let dispSubtitle = subTitle;

  if (ownerInfo && showBusinessCover) {
    dispTitle = ownerInfo?.data?.shopName ?? `Parts for sale by ${ownerInfo?.data?.name}`;
    dispSubtitle = ownerInfo?.data?.shopAddress ?? `Try browse parts with filters`
  }

  const initialFilterWithUserIdAndListingType = {
    UserId: ownerIdOfParts
  }

  // keywords and other filters are separated since keyword is read from a different textbox other than fiter dialog
  // these params should be included at market.route page to reset the whole component when these params are changed
  const { searchParamValue: partsFilterObjFromUrl, changeSearchParam: setPartsFilterObj } = useSearchParamsState<string>(SearchParams.partsFilterObj, JSON.stringify(initialFilterWithUserIdAndListingType));
  const { searchParamValue: partsFilterKeywords, changeSearchParam: setPartsFilterKeywords } = useSearchParamsState<string>(SearchParams.partsFilterKeywords, '');
  const { searchParamValue: partsFilterEnabled, changeSearchParam: setPartsFilterEnabled } = useSearchParamsState<string>(SearchParams.partsFilterEnabled, 'true');


  const partsFilterObj = JSON.parse(partsFilterObjFromUrl === `{}` ? savedFilter : partsFilterObjFromUrl);

  // Pagination starts
  const [query, setQuery] = useState({
    // filter: partsFilter,
    keywords: partsFilterKeywords,
    filter: partsFilterEnabled === 'true' ? partsFilterObj : initialFilterWithUserIdAndListingType,
    limit: config.pageSize,
    offset: 0,
    ownerType
  });
  const { data, isFetching } = useSearchPartsQuery(query);
  const { mergedData, handleLoadMore } = usePaginatedData(data, setQuery);
  // Pagination ends

  const handleFilterChange = async (filter: PartsFilterType) => {
    const filterObj = JSON.stringify(filter);
    setPartsFilterObj(filterObj);
    setLocalStorage(partsFilterKey, filterObj);
  }

  const handleFilterEnable = (enabled: boolean) => {
    setPartsFilterEnabled(enabled ? 'true' : 'false');
  }

  const handleSearchChange = (keyword: string) => {
    const lowercaseKw = keyword.toLowerCase();
    setPartsFilterKeywords(lowercaseKw);
  }

  const handleSelect = (p: Part) => {
    const path = `/sparepart/${p.id}`;
    navigate(path);
  };

  const handleAdd = () => {
    const path = `/sparepart/create`;
    navigate(path);
  };

  const onShare = () => {
    const link = `${window.location.origin}/sparepart/shop/${ownerIdOfParts}`;
    void copyToClipboard(link);
    PopupManager.showSuccessToast(`Link copied! ${link}`);
  }

  const coverImage = ownerInfo.data?.images[UserImageType.BusinessCover];

  return <>
    {ownerInfo?.data && <Grid container justifyContent="end" alignItems="center">
      <Link href='#' onClick={onShare} variant="body2" margin={1}>
        Share This Page
      </Link>
      <Grid item><PhoneDial phone={ownerInfo?.data?.descPhone} /></Grid>
    </Grid>}
    <PageHeader header={dispTitle} subHeader={dispSubtitle} showEditOptions={ownerType === OwnerTypes.My}/>
    {/* {shopOwner?.data && coverImage && <ImageListItem> <img style={{ 'height': '300px', 'marginBottom': '10px' }} src={coverImage} /></ImageListItem>} */}

    {showBusinessCover && <CoverImage src={coverImage} showEditOptions={ownerType === OwnerTypes.My} />}

    <Grid container justifyContent='end' alignItems='center'>
      <Grid item>
        <PartsFilter enabled={partsFilterEnabled === 'true'} initialState={partsFilterObj} loading={false} open={openFilter} onFilterChange={handleFilterChange} onClose={() => setOpenFilter(false)} />
      </Grid>
      <Grid item>
        <SearchBox placeHolder="Search Spare Parts" filterEnabled={partsFilterEnabled === 'true'} onFilterEnable={handleFilterEnable} onChange={handleSearchChange} value={partsFilterKeywords} onFilterOpen={() => setOpenFilter(true)} />
      </Grid>
    </Grid>

    <PartsList
      viewMode={viewMode}
      onAdd={handleAdd}
      hasMore={data?.length > 0}
      loading={isFetching}
      items={mergedData}
      onLoadMore={handleLoadMore}
      onSelect={handleSelect} />
  </>
}