import dayjs from "dayjs";
import { ServiceRecord } from "../types/ServiceRecord";
import { config } from "../config";
import { SelectOption } from "../types/SelectOption";
import { User } from "../types/User";
import { Vehicle } from "../types/Vehicle";
import { ServiceType, serviceTypes } from "../selectOptions/serviceTypes";
import { VehicleFilterType } from "../types/filters/VehicleFilterType";
import { NULL_LOCATION, RANDOM_COLORS } from "./constants";
import { PartsFilterType } from "../types/filters/PartsFilterType";
import { RecordsFilterType } from "../types/filters/RecordsFilterType";
import { Part } from "../types/Part";
import { AxiosResponse } from "axios";
import { setLocalStorage } from "./localData";
import { LocalStorageKeys } from "../enum/localStorageKeys.enum";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { NetworkResponse } from "../types/NetworkResponse";
import { PopupManager } from "./popupManager";


export const isMobile = () => {
  return window.innerWidth <= config.mobileWidth;
}
/**
 * 
 * @param filteObj 
 * @param defaultValues 
 * @returns 
 */
export const getActiveFilters = <T>(filteObj: any, defaultValues: any) => {
  const active: Array<{ key: keyof T, value: any }> = [];
  Object.entries(filteObj).forEach(([key, val]) => {
    const k = key as keyof T;

    if (val !== defaultValues[k] && val !== '' && val !== -1) {
      if (Array.isArray(val)) {
        if (val.length > 0) {
          active.push({ key: k, value: val });
        }
      } else {
        active.push({ key: k, value: val });
      }
    }
  });
  return active;
}

export const getLocation = (lat: number, lng: number) => (lat === NULL_LOCATION.LAT || lng === NULL_LOCATION.LNG) ? null : { lat: Number(lat), lng: Number(lng) };

export const isEqualArays = (a: any[], b: any[]) => {

  let match = true;
  for (const i of a) {
    if (!b.includes(i)) {
      match = false;
      break;
    }
  }

  if (!match) {
    return false;
  }

  for (const i of b) {
    if (!a.includes(i)) {
      match = false;
      break;
    }
  }

  return match;
}

export const distance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const R = 6371; // km (change this constant to get miles)
  const dLat = (lat2 - lat1) * Math.PI / 180;
  const dLon = (lon2 - lon1) * Math.PI / 180;
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  if (d > 1) return `${Math.round(d)}km`;
  else if (d <= 1) return `${Math.round(d * 1000)}m`;
  return d;
}

export const generateKeywordsString = (keywords: string) => {
  return `keywords=${keywords}`;
}

export const generateFilterString = (f: Partial<VehicleFilterType | PartsFilterType | RecordsFilterType>) => {
  return `filter=${JSON.stringify(f)}`;
}

export const generatePaginationString = (limit: number | undefined, offset: number | undefined) => {
  const paginationString = limit !== undefined && offset !== undefined ? `offset=${offset}&limit=${limit}` : ``;
  return paginationString;
}

export const getHashFromString = (s: string) => {
  if (!s) {
    return "";
  }
  let res = "";

  s.split("").forEach((c) => {
    const code = c.charCodeAt(0) + 103;
    res += `${code}`;
  });
  return res;
};

export const getStringFromHash = (s: string) => {
  if (!s) {
    return "";
  }
  let res = "";

  while (s.length > 0) {
    const code = Number(s.substr(0, 3)) - 103;
    res += String.fromCharCode(code);
    s = s.substr(3);
  }
  return res;
};

export const encryptProps = (obj: any) => {
  const newObj: any = {};
  Object.entries(obj).forEach(([key, val]) => {
    newObj[getHashFromString(key)] = getHashFromString(`${val}`);
  });
  return newObj;
};

export const withNotSelectedOption = (options: SelectOption[]) => {
  return [{ id: -1, label: '' }, ...options];
}


// This is for when admin login as other user
export const forcedSignInUser = (user: User | undefined) => {
  if (user) {
    PopupManager.showSuccessToast(`Logged in as ${user.name}`);

    setTimeout(() => {
      setLocalStorage(LocalStorageKeys.forcedLoggedInUser, JSON.stringify(user));
      window.location.reload();
    }, 100);
  } else {
    PopupManager.showErrorToast(`Log in failed`);
  }
}

export const getRandomColor = (index: number) => {
  const idx = index % RANDOM_COLORS.length;
  return RANDOM_COLORS[idx];
}

export const appendQueryParam = (url: string, key: string, value: string) => {
  if(!url) {
    return undefined;
  }
  if (url.includes("?")) {
    return `${url}&${key}=${value}`;
  } else {
    return `${url}?${key}=${value}`;
  }
};

export const getFirstChar = (name?: string): string => {
  if (name == null || name.length === 0) {
    return "O";
  }
  return name.split("")[0].toUpperCase();
};

export const parseEntityId = (vid: string | undefined) => {
  const num = Number(vid);
  if (isNaN(num)) {
    return -1;
  } else {
    return num;
  }
};

export const isAdmin = (user: User | null | undefined): boolean => {
  return user?.id === config.adminUserId;
};

export const getFormattedDate = (d?: Date): string => {
  if (d == null) {
    return "";
  }
  return dayjs(d).format("YYYY/MM/DD ddd h:m a");
};

export const getFormatteShortdDate = (d?: Date): string => {
  if (d == null) {
    return "";
  }
  return dayjs(d).format("MM DD YY");
};

export const handleNetworkResponse = <T>(response: AxiosResponse<any, any>, defaultResponse: any, skipShowErrors?: boolean) => {
  if (response.data.status) {
    return response.data;
  } else {
    if (!skipShowErrors) {
      PopupManager.showErrorToast(response.data.data);
    }
    return {
      data: defaultResponse,
      error: response.data.data
    }
  }
}

// export const handleNetworkResponse2 = (response: AxiosResponse<any, any>, skipShowErrors?: boolean) => {
//   if (!response.data.status && !skipShowErrors) {
//     PopupManager.showErrorToast(response.data.data);
//   }
//   const ret = {
//     data: response.data,
//   };
//   return ret;
// }

export const getServiceTypes = (categories: number[]): SelectOption[] =>
  serviceTypes.filter((s) => categories.includes(s.id));

export const copyToClipboard = async (text: string) => {
  await navigator.clipboard.writeText(text)
}

export const isDev = (): boolean => {
  return location.hostname === 'localhost';
  // return process.env.NODE_ENV === "development";
};

export const calcFuelConsumption = (records: ServiceRecord[]) => {
  const recordsWithMillage = records.filter(r => r.odo >= 0);
  const totalVol = records.filter(r => r.categories.includes(ServiceType.FuelTopUp)).map(r => (r.extraProps).volume).reduce((a, b) => (Number(a) + Number(b)), 0);

  if (recordsWithMillage.length === 0 || totalVol === 0) {
    return '--- km/l';
  }
  const lastFilledVol = (records[0].extraProps).volume;
  const netVol = totalVol - lastFilledVol;

  const lastKm = recordsWithMillage[0].odo;
  const firstKm = recordsWithMillage[recordsWithMillage.length - 1].odo;
  const distance = lastKm - firstKm;
  const res = distance / netVol;

  const fin = res.toFixed(1)
  return isNaN(Number(fin)) ? '--- km/l' : `${fin} km/l`;
}

export const hasUpdateAccess = (
  resource: Vehicle | ServiceRecord | Part | null,
  user: User | undefined | null
) => {
  return Boolean(
    user &&
    resource &&
    user.id === resource.UserId
    // (user.id === config.adminUserId || user.id === resource.UserId)
  );
};

export const decodeUrl = (url: string) => {
  // const ur = '/1/vehicle/1/';
  // /1/vehicle/1/record/2
  const params = url.split('?')[0].split('/');
  const uid = params[1];
  const vid = params.length >= 3 ? params[3] : null;
  const rid = params.length >= 5 ? params[5] : null;

  return {
    uid,
    vid,
    rid
  }
}

export const removeSpecialCharactorsAndCapitalize = (str: string): string =>
  str.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();

export const getColorCodeForText = (text: string) => {
  const colorCodes = [
    '#A93226',
    '#7D3C98',
    '#2E86C1',
    '#17A589',
    '#D4AC0D',
    '#D35400'
  ];
  const chars = text.split('');
  let sum = 0;
  chars.forEach(c => {
    sum = sum + c.charCodeAt(0);
  })
  const colIndex = sum % colorCodes.length;
  return colorCodes[colIndex];
}
